<template>
  <div v-if="valor" @click="onClick" title="Remover Parametro de Busca">
    <strong v-if="label !== 'Tipo'">{{label}}:</strong>
    <strong v-else>{{ valor.includes(',') ? 'Tipos:' : 'Tipo:' }}</strong>
    <span>{{truncate(valor, { length: 50})}}</span>
  </div>
</template>

<script>
const truncate = require("lodash.truncate");

export default {
  name: "BotaoParametroBusca",
  props: {
    onClick: {
      type: Function,
      required: true
    },
    label: {
      type: String,
      required: true
    },
    valor: {
      type: [String, Number],
      required: true
    }
  },
  methods: {
    truncate
  }
};
</script>

<style lang="scss" scoped>
div {
  background-color: #368d49;
  margin: 0.5rem;
  padding: 0rem 0.5rem;
  width: auto;
  color: white;
  cursor: pointer;
  font-size: 0.9rem;

  strong {
    margin-right: 0.3rem;
  }
  span:after {
    content: "x";
    color: silver;
    margin-left: 0.5rem;
  }
}
.alto-contraste{
  div{
    background-color: #213b27;
  }
}
</style>