<template>
  <div>
    <div class="centro">
      <h1>Projetos do Governador</h1>
      <BuscaSimplesCards
        titulo="Consultas Rápidas"
        :cards="cardsProjetoGovernador"
      />
    </div>
    <div class="pesquise">
      <div class="centro">
        <form @submit="buscar">
          <h2>Pesquise aqui:</h2>
          <!-- <div class="info-field">
            <div class="info-button">
              <div class="info-icon">?</div>
            </div>
            <div class="info-tooltip">
              <div class="info-content">
                <h2>Ajuda para busca personalizada:</h2>
                <p>- Para busca de vários termos, inserir os termos separados por um espaço ou pelo caractere '&'</p>
                <p>- Para exclusão de um termo, inserir o caractere '-' antes do termo</p>
                <p>- Para busca de um termo ou outro, inserir os termos separados pela palavra 'OR'</p>
                <p>- Para busca de uma frase, inserir a frase dentro de aspas duplas ("")</p>
                <br />
                <p><span class="exemplo">Exemplo:</span> Se quiser pesquisar a frase 'Declara de utilidade pública a entidade que especifica', mas excluir o termo 'segurança'</p>
                <p class="exemplo">"Declara de utilidade pública a entidade que especifica" -segurança</p>
              </div>
            </div>
          </div> -->
          <div class="row2">
            <div class="row2">
              <div class="item">
                <label>Ano</label>
                <input type="number" v-model="filtro.ano" />
              </div>
              <div class="item">
                <label>Status andamento</label>
                <select v-model="filtro.em_tramitacao">
                  <option :value="null">Todos</option>
                  <option value="true">Em tramitação</option>
                  <option value="false">Finalizado</option>
                </select>
              </div>
            </div>
            <div class="item">
              <label>Assunto</label>
              <input type="text" v-model="filtro.assunto" />
            </div>
          </div>
          <div class="row2">
            <div class="grupo">
              <p>Data do Ofício</p>
              <div class="data">
                <div class="item">
                  <label>De</label>
                  <date-picker
                    v-model="filtro.data_oficio_inicio"
                    :formatter="datepickerFormatter"
                  ></date-picker>
                </div>
                <div class="item">
                  <label>Até</label>
                  <date-picker
                    v-model="filtro.data_oficio_fim"
                    :formatter="datepickerFormatter"
                  ></date-picker>
                </div>
              </div>
            </div>
            <div class="direita">
              <div class="row2">
                <div class="item">
                  <label>Nº do Ofício mensagem</label>
                  <input type="text" v-model="filtro.numero_oficio_msg" />
                </div>
                <div class="item">
                  <label>Nº do Projeto de Lei</label>
                  <input
                    type="text"
                    v-model="filtro.numero_processo_projeto_lei"
                  />
                </div>
              </div>
              <div class="row2">
                <div class="item">
                  <label>Nº Lei</label>
                  <input type="text" v-model="filtro.numero_lei" />
                </div>
                <div class="item">
                  <label>Nº do Autográfo de Lei</label>
                  <input type="text" v-model="filtro.numero_autografo_lei" />
                </div>
              </div>
            </div>
          </div>
          <div class="botoes">
            <button type="button" @click="limparFiltro">Limpar</button>
            <button type="submit">Buscar</button>
          </div>
        </form>
        <div class="seta">
          <img
            src="../../assets/images/triangulo-form.png"
            class="triangulo t-branco"
            alt=" "
          />
          <img
            src="../../assets/images/triangulo-form-black.png"
            class="triangulo t-preto"
            alt=" "
          />
        </div>
      </div>
    </div>

    <div class="centro campo-resultados" id="resultados">
      <div class="nav-resultados">
        <h2>Resultados da Pesquisa</h2>
        <div class="dados-abertos" v-show="!carregando && !erro">
          <p>
            <span>Dados Abertos:</span>
            <a @click="abrirDadosAbertos('json')">JSON</a>
            <a @click="abrirDadosAbertos('csv')">CSV</a>
          </p>
        </div>

        <ParametrosBusca
          :funcaoBuscaSimples="
            (event) => {
              this.termo = null;
              this.fazerBuscaSimples(event);
            }
          "
          :funcaoBuscaAvancada="buscar"
          :filtro="filtro"
          contexto="projeto_governador"
          v-show="!carregando && !erro"
        />

        <div class="npagina" v-show="!carregando">
          <label>Resultados por Página</label>
          <select :value="qtd_por_pagina" @input="mudouQtdPagina">
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        </div>
        <div class="nresultados" v-show="!carregando && !erro">
          <p>
            <strong>{{ totalResultadosTexto }}</strong>
            {{
              totalResultadosTexto === 1
                ? ` resultado encontrado`
                : ` resultados encontrados`
            }}
          </p>
        </div>
      </div>
      <div>
        <TabelaResultadosProjetoGovernador
          :projetos="projetos"
          v-if="!carregando && !erro"
        ></TabelaResultadosProjetoGovernador>

        <Loading v-if="carregando" size="medium"></Loading>
        <ErroResultados v-if="!carregando && erro"></ErroResultados>
      </div>

      <paginate
        v-if="!erro && !carregando"
        v-model="pagina"
        :page-count="pageCount"
        :click-handler="mudouPagina"
        :prev-text="'Anterior'"
        :next-text="'Próxima'"
        :container-class="'className'"
        active-class="active-page"
      ></paginate>
    </div>
  </div>
</template>

<script>
import { format, parseISO } from "date-fns";

import Paginate from "vuejs-paginate";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import "vue2-datepicker/locale/pt-br";

import ProjetoGovernadorApi from "../../services/ProjetoGovernadorApi";
import { datepickerFormatter } from "../../helpers/datepickerFormatter";
import TabelaResultadosProjetoGovernador from "../../components/pesquisa_projeto_governador/TabelaResultadosProjetoGovernador";
import tryParseInt from "../../helpers/tryParseInt";
import Loading from "../../components/Loading";
import ErroResultados from "../../components/ErroResultados";
import ParametrosBusca from "../../components/ParametrosBusca";
import { mixinProjetoGovernador } from "../../mixins/mixinProjetoGovernador";
import metaDados from "../../helpers/metaDados";

export default {
  name: "ProjetosGovernador",
  metaInfo() {
    return {
      title: "Pesquisa de Projetos de Autoria do Governador",
      titleTemplate: "%s - Casa Civil do Estado de Goiás",
      meta: [
        {
          vmid: "description",
          name: "description",
          property: "description",
          content:
            "Página de acesso e pesquisa aos projetos de lei propostos pelo Governo do Estado de Goiás.",
        },
        {
          vmid: "og:description",
          property: "og:description",
          content:
            "Página de acesso e pesquisa aos projetos de lei propostos pelo Governo do Estado de Goiás.",
        },
        {
          vmid: "og:title",
          property: "og:title",
          content:
            "Pesquisa de Projetos de Autoria do Governador - Casa Civil do Estado de Goiás",
        },
        {
          vmid: "og:type",
          property: "og:type",
          content: "article",
        },
        {
          vmid: "twitter:description",
          name: "twitter:description",
          property: "twitter:description",
          content:
            "Página de acesso e pesquisa aos projetos de lei propostos pelo Governo do Estado de Goiás.",
        },
        {
          vmid: "twitter:title",
          name: "twitter:title",
          property: "twitter:title",
          content:
            "Pesquisa de Projetos de Autoria do Governador - Casa Civil do Estado de Goiás",
        },
        ...metaDados(this.$route),
      ],
    };
  },
  mixins: [mixinProjetoGovernador],
  data() {
    return {
      pagina: 1,
      totalResultados: null,
      totalResultadosTexto: null,
      qtd_por_pagina: 10,
      projetos: [],
      carregando: true,
      erro: false,
      filtro: {
        numero_processo_projeto_lei: null,
        assunto: null,
        numero_oficio_msg: null,
        numero_autografo_lei: null,
        numero_lei: null,
        em_tramitacao: null,
        ano: null,
        data_oficio_inicio: null,
        data_oficio_fim: null,
      },
      datepickerFormatter,
    };
  },
  components: {
    Paginate,
    DatePicker,
    TabelaResultadosProjetoGovernador,
    Loading,
    ErroResultados,
    ParametrosBusca,
  },
  created() {
    this.projetoGovernadorApi = new ProjetoGovernadorApi();

    this.carregarQtdPorPagina();
    this.carregarProjetos();
  },
  watch: {
    $route: function (to, from) {
      const mudouQuery =
        to.query.ano != from.query.ano ||
        to.query.numero_processo_projeto_lei !=
          from.query.numero_processo_projeto_lei ||
        to.query.assunto != from.query.assunto ||
        to.query.numero_oficio_msg != from.query.numero_oficio_msg ||
        to.query.numero_autografo_lei != from.query.numero_autografo_lei ||
        to.query.numero_lei != from.query.numero_lei ||
        to.query.em_tramitacao != from.query.em_tramitacao ||
        to.query.data_oficio_inicio != from.query.data_oficio_inicio ||
        to.query.data_oficio_fim != from.query.data_oficio_fim ||
        to.query.pagina != from.query.pagina ||
        to.query.qtd_por_pagina != from.query.qtd_por_pagina;

      if (
        mudouQuery &&
        from.name == "ProjetosGovernador" &&
        to.name == "ProjetosGovernador"
      ) {
        this.carregarProjetos();
      }
    },
  },
  methods: {
    buscar: function (event) {
      event.preventDefault();
      this.$router.push({
        name: "ProjetosGovernador",
        query: {
          ...this.filtro,
          pagina: 1,
          qtd_por_pagina: this.qtd_por_pagina,
          ...this.convertePeriodosDatasParaString(),
        },
      });
      this.$scrollTo("#resultados", 700, {
        offset: -100,
        cancelable: false,
      });
    },
    mudouQtdPagina: function (event) {
      const novaQtdPagina = parseInt(event.target.value);
      if (novaQtdPagina < this.qtd_por_pagina) {
        this.qtd_por_pagina = novaQtdPagina;
        this.mudouPagina(this.pagina);
      } else {
        let novaPagina = Math.ceil(
          (this.qtd_por_pagina * this.pagina) / novaQtdPagina
        );

        this.qtd_por_pagina = novaQtdPagina;
        this.mudouPagina(novaPagina);
      }
    },
    mudouPagina: function (page) {
      this.pagina = page;

      const filtro = {
        ano: this.getParam("ano"),
        numero_processo_projeto_lei: this.getParam(
          "numero_processo_projeto_lei"
        ),
        assunto: this.getParam("assunto"),
        numero_oficio_msg: this.getParam("numero_oficio_msg"),
        numero_autografo_lei: this.getParam("numero_autografo_lei"),
        numero_lei: this.getParam("numero_lei"),
        em_tramitacao: this.getParam("em_tramitacao"),
        ...this.convertePeriodosDatasParaString(),
      };

      this.$router.push({
        name: "ProjetosGovernador",
        query: {
          ...filtro,
          pagina: this.pagina,
          qtd_por_pagina: this.qtd_por_pagina,
        },
      });
      this.$scrollTo("#resultados", 0, {
        offset: -100,
        cancelable: false,
      });
    },
    carregarProjetos: async function () {
      try {
        this.erro = false;
        this.carregando = true;

        const pagina = this.getParam("pagina") || 1;
        this.pagina = parseInt(pagina);

        this.qtd_por_pagina =
          this.getParam("qtd_por_pagina") || this.qtd_por_pagina;

        this.armazenarQtdPorPagina();

        this.filtro = {
          ano: tryParseInt(this.getParam("ano")),
          numero_processo_projeto_lei: this.getParam(
            "numero_processo_projeto_lei"
          ),
          assunto: this.getParam("assunto"),
          numero_oficio_msg: this.getParam("numero_oficio_msg"),
          numero_autografo_lei: this.getParam("numero_autografo_lei"),
          numero_lei: this.getParam("numero_lei"),
          em_tramitacao: this.getParam("em_tramitacao"),
          ...this.convertPeriodosStringParaDatas(),
        };

        if (this.projetoGovernadorApi.cancelTokens.getProjetosGovernador) {
          this.projetoGovernadorApi.cancelTokens.getProjetosGovernador.cancel();
        }

        const data = await this.projetoGovernadorApi.getProjetosGovernador({
          ...this.filtro,
          ...this.convertePeriodosDatasParaString(),
          page: this.pagina,
          qtd_por_pagina: this.qtd_por_pagina,
        });
        this.projetos = data.resultados;
        this.totalResultados = data.total_resultados;
        this.totalResultadosTexto = data.total_resultados.toLocaleString();

        this.carregando = false;
      } catch (err) {
        if (this.projetoGovernadorApi.isCancel(err)) {
          return;
        }

        console.log(err);
        this.erro = true;
        this.carregando = false;
        this.projetos = [];
        this.totalResultados = null;
      }
    },
    limparFiltro: function () {
      this.filtro = {
        numero_processo_projeto_lei: null,
        assunto: null,
        numero_oficio_msg: null,
        numero_autografo_lei: null,
        numero_lei: null,
        em_tramitacao: null,
        ano: null,
        data_oficio_inicio: null,
        data_oficio_fim: null,
      };
    },
    getParam: function (name) {
      if (this.$route && this.$route.query) {
        return this.$route.query[name] || null;
      }
      return null;
    },
    convertePeriodosDatasParaString: function () {
      return {
        data_oficio_inicio: this.filtro.data_oficio_inicio
          ? format(this.filtro.data_oficio_inicio, "yyyy-MM-dd")
          : null,
        data_oficio_fim: this.filtro.data_oficio_fim
          ? format(this.filtro.data_oficio_fim, "yyyy-MM-dd")
          : null,
      };
    },
    convertPeriodosStringParaDatas: function () {
      const data_oficio_inicio = this.getParam("data_oficio_inicio");
      const data_oficio_fim = this.getParam("data_oficio_fim");

      return {
        data_oficio_inicio: data_oficio_inicio
          ? parseISO(data_oficio_inicio)
          : null,
        data_oficio_fim: data_oficio_fim ? parseISO(data_oficio_fim) : null,
      };
    },
    abrirDadosAbertos: function (format) {
      if (
        this.totalResultados == undefined ||
        this.totalResultados == null ||
        this.totalResultados > 1000
      ) {
        alert(
          "O download dos dados é limitado a 1000 registros. Refaça sua consulta utilizando os filtros disponíveis."
        );
      } else {
        const params = new URLSearchParams({
          ano: this.getParam("ano") || "",
          numero_processo_projeto_lei:
            this.getParam("numero_processo_projeto_lei") || "",
          assunto: this.getParam("assunto") || "",
          numero_oficio_msg: this.getParam("numero_oficio_msg") || "",
          numero_autografo_lei: this.getParam("numero_autografo_lei") || "",
          numero_lei: this.getParam("numero_lei") || "",
          em_tramitacao: this.getParam("em_tramitacao") || "",
          data_oficio_inicio: this.getParam("data_oficio_inicio") || "",
          data_oficio_fim: this.getParam("data_oficio_fim") || "",
        }).toString();
        window.open(
          `${process.env.VUE_APP_BASE_API_URL}/api/v1/pesquisa/projetos_governador/dados_abertos.${format}?${params}`,
          "_blank"
        );
      }
    },
    armazenarQtdPorPagina() {
      localStorage.setItem(
        "qtd_por_pagina_projeto_governador",
        this.qtd_por_pagina
      );
    },
    carregarQtdPorPagina() {
      const qtd = localStorage.getItem("qtd_por_pagina_projeto_governador");
      if (qtd) {
        this.qtd_por_pagina = tryParseInt(qtd);
      }
    },
  },
  computed: {
    pageCount: function () {
      if (
        this.totalResultados != undefined &&
        this.totalResultados != null &&
        this.qtd_por_pagina != null &&
        this.qtd_por_pagina != undefined &&
        this.totalResultados > 0 &&
        this.qtd_por_pagina > 0
      ) {
        return Math.ceil(this.totalResultados / this.qtd_por_pagina);
      }
      return 0;
    },
  },
};
</script>

<style lang="scss" scoped>
.info-field {
  position: relative;
  display: inline-block;
  margin-left: 1rem;
}

.info-button {
  width: 30px;
  height: 30px;
  background-color: #ffa900;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.info-button:hover {
  background-color: #ffaa00ae;
}

.info-icon {
  color: #375a3a;
  font-weight: bold;
  font-size: 18px;
}

@media screen and (min-width: 320px) and (max-width: 425px) {
  .info-tooltip {
    width: 60vw;
    height: max-content;
    position: absolute;
    transform: translateX(-50%);
    background-color: #ffa900;
    padding: 10px;
    border-radius: 4px;
    display: none;
    z-index: 1;
    margin-top: 0.5rem;
  }

  .info-field:hover .info-tooltip {
    display: block;
  }

  .info-content {
    color: black;
    .exemplo {
      color: #375a3a;
      font-weight: bold;
    }
  }

  div.info-content h2 {
    color: #375a3a;
    border-bottom: #375a3a solid 0.15rem;
    margin-bottom: 1rem;
  }

  .info-content p {
    margin: 0;
    padding: 0;
    margin-bottom: 5px;
  }
}

@media screen and (min-width: 426px) and (max-width: 1024px) {
  .info-tooltip {
    width: 60vw;
    height: max-content;
    position: absolute;
    transform: translateX(-50%);
    background-color: #ffa900;
    padding: 10px;
    border-radius: 4px;
    display: none;
    z-index: 1;
    margin-top: 0.5rem;
    left: 20vw;
  }

  .info-field:hover .info-tooltip {
    display: block;
  }

  .info-content {
    color: black;
    .exemplo {
      color: #375a3a;
      font-weight: bold;
    }
  }

  div.info-content h2 {
    color: #375a3a;
    border-bottom: #375a3a solid 0.15rem;
    margin-bottom: 1rem;
  }

  .info-content p {
    margin: 0;
    padding: 0;
    margin-bottom: 5px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .info-tooltip {
    width: max-content;
    height: max-content;
    position: absolute;
    transform: translateX(-50%);
    background-color: #ffa900;
    padding: 1rem;
    border-radius: 4px;
    display: none;
    z-index: 1;
    margin-top: 0.5rem;
    left: 25vw;
  }

  .info-field:hover .info-tooltip {
    display: block;
  }

  .info-content {
    color: black;
    .exemplo {
      color: #375a3a;
      font-weight: bold;
    }
  }

  div.info-content h2 {
    color: #375a3a;
    border-bottom: #375a3a solid 0.15rem;
    margin-bottom: 1rem;
  }

  .info-content p {
    margin: 0;
    padding: 0;
    margin-bottom: 5px;
  }
}

@media screen and (min-width: 1441px) and (max-width: 2560px) {
  .info-tooltip {
    width: max-content;
    height: max-content;
    position: absolute;
    transform: translateX(-50%);
    background-color: #ffa900;
    padding: 1rem;
    border-radius: 4px;
    display: none;
    z-index: 1;
    margin-top: 0.5rem;
    left: 15vw;
  }

  .info-field:hover .info-tooltip {
    display: block;
  }

  .info-content {
    color: black;
    .exemplo {
      color: #375a3a;
      font-weight: bold;
    }
  }

  div.info-content h2 {
    color: #375a3a;
    border-bottom: #375a3a solid 0.15rem;
    margin-bottom: 1rem;
  }

  .info-content p {
    margin: 0;
    padding: 0;
    margin-bottom: 5px;
  }
}

h1 {
  font-family: "Poppins-Bold";
  color: #2c3f2b;
  padding-left: 0.5rem;
  border-left: #ffa900 solid 0.15rem;
  margin: 2.5rem 0rem 1.5rem 1rem;
  font-size: 1.5rem;
  line-height: 2rem;
}
.pesquise {
  background-color: #375a3a;
  padding: 0.5rem 1rem 5rem;
  h2 {
    color: white;
    font-family: "Poppins-ExtraLight";
    font-size: 1.1rem;
    display: inline-table;
    padding-right: 1rem;
    border-bottom: #ffa900 solid 0.15rem;
    margin: 0rem;
  }
  label {
    color: white;
    font-size: 0.9rem;
  }
  input {
    border: none;
    width: calc(100% - 0.8rem);
    height: 2rem;
    border-radius: 0.2rem;
    border: none;
    padding-left: 0.5rem;
    font-family: "Poppins";
    letter-spacing: 0.03rem;
    font-size: 1rem;
  }
  .campo-preenchido {
    background-color: #fffed4;
    // border: solid .15rem #ffa900;
    // box-shadow: 0rem 0rem 0rem 0.13rem #ffa900;
    // -moz-box-shadow: 0rem 0rem 0rem 0.13rem #ffa900;
    // -webkit-box-shadow: 0rem 0rem 0rem 0.13rem #ffa900;
  }
  select {
    width: calc(100% - 0.2rem);
    height: 2rem;
    border: none;
    border-radius: 0.2rem;
    padding-left: 0.5rem;
    background-color: white;
    font-family: "Poppins";
    letter-spacing: 0.03rem;
    font-size: 1rem;
  }
  .grupo {
    border: #ffa900 solid 0.15rem;
    margin: 0.5rem 0rem;
    padding: 0.5rem 0.5rem;
    border-radius: 0.5rem;
    .data {
      display: grid;
      grid-template-columns: auto auto;
      gap: 1rem;
      .mx-datepicker {
        width: 100%;
      }
      .mx-input {
        height: 1rem !important;
      }
    }
    p {
      margin: 0rem;
      padding: 0rem;
      color: white;
    }
  }
  .botoes {
    float: right;
    margin: 1rem 0rem;
    button {
      margin-left: 1rem;
      padding: 0.1rem 2rem;
      font-family: "Poppins-Medium";
      font-size: 0.9rem;
    }
    button:first-child {
      background: transparent;
      border: white solid 0.15rem;
      color: white;
      padding: 0.1rem 2rem;
      cursor: pointer;
    }
    button:last-child {
      background-color: #ffa900;
      color: #2c3f2b;
      border: solid 0.15rem #ffa900;
      cursor: pointer;
      font-family: "Poppins-Medium";
    }
  }
  .item {
    display: grid;
    margin: 0.5rem 0rem;
  }
  .seta {
    img {
      width: 5rem;
      position: absolute;
      right: 1.2rem;
      margin-top: 3.5rem;
    }
    .t-preto {
      display: none;
    }
  }
}
.campo-resultados {
  margin: 1rem;
  h2 {
    color: #2c3f2b;
    font-family: "Poppins-ExtraLight";
    font-size: 1.1rem;
    display: table;
    padding-right: 1rem;
    border-bottom: #ffa900 0.15rem solid;
    margin: 1.5rem 0rem 0rem 1rem;
  }
  .dados-abertos {
    padding-left: 0.5rem;
    width: 13rem;
    margin: 1rem 1rem 0rem 0rem;
    p,
    span {
      margin: 0rem;
      font-size: 0.8rem;
      font-family: "Poppins-ExtraLight";
      a {
        margin: 0rem 0.35rem 0rem 1rem;
        text-decoration: none;
        color: #2c3f2b;
        font-family: "Poppins-Medium";
        cursor: pointer;
      }
    }
    p {
      background-color: #edf2ee;
      padding-left: 0.5rem;
      height: 1.3rem;
    }
  }

  .nav-resultados {
    margin-bottom: 2rem;
  }
  .nresultados {
    p {
      font-size: 0.8rem;
      margin: 0.5rem 0rem -2.5rem 1rem;
      strong {
        font-family: "Poppins-Medium";
      }
    }
  }
  .npagina {
    margin: 0.5rem 1rem 0.5rem 1rem;
    label {
      font-family: "Poppins-Medium";
      font-weight: 900;
      margin-right: 0.5rem;
      color: #2c3f2b;
      font-size: 0.9rem;
    }
    select {
      border: none;
      background-color: #edf2ee;
      font-size: 0.9rem;
      margin-top: 0.5rem;
    }
  }
  .campo-resultados {
    .dados-abertos {
      padding-left: 0.5rem;
      width: 13rem;
      margin: -2rem 1rem 0rem auto;
    }
    .nresultados {
      p {
        font-size: 0.8rem;
        margin: -1rem 0rem 3rem 0.5rem;
        strong {
          font-family: "Poppins-Medium";
        }
      }
    }
  }
}
@media screen and (min-width: 31rem) {
  .nav-resultados {
    margin-bottom: 0rem;
  }
  .nresultados {
    p {
      margin-bottom: 0rem;
    }
  }
}
@media screen and (min-width: 64rem) {
  h1 {
    margin-left: 0rem;
    font-size: 1.8rem;
  }
  .pesquise {
    padding-bottom: 5rem;
    h2 {
      margin-top: 1rem;
      margin-bottom: 1rem;
      font-size: 1.3rem;
    }
    .row2 {
      grid-template-columns: calc(50% - 0.5rem) calc(50% - 0.5rem);
      gap: 1rem;
      display: grid;
    }
    .grupo {
      padding: 0.65rem 2rem;
    }
    .botoes {
      margin-right: 0.3rem;
      margin-top: 1rem;
      button {
        font-size: 1rem;
        padding: 0.1rem 4rem !important;
        border-radius: 0.2rem;
      }
    }
    .seta {
      img {
        right: 5rem;
        margin-top: 3.5rem;
      }
    }
  }
  .campo-resultados {
    h2 {
      margin-left: 0rem;
      font-size: 1.3rem;
    }
    .dados-abertos {
      padding-left: 0.5rem;
      width: 13rem;
      margin: -2rem 0.5rem 0rem auto;
    }
    .nresultados {
      max-width: 14rem;
      text-align: left;
      transform: translateY(-1.5rem);
      p {
        font-size: 0.8rem;
        margin: 0.3rem 0rem 2rem 0rem;
        strong {
          font-family: "Poppins-Medium";
        }
      }
    }
    .npagina {
      margin-right: 0.5rem;
      margin-top: 0.5rem;
      text-align: right;
    }
  }
}
.alto-contraste {
  .pesquise {
    background-color: #213b27;
    .seta {
      .t-branco {
        display: none;
      }
      .t-preto {
        display: block;
      }
    }

    .botoes {
      button:last-child {
        background-color: #ffa900;
        color: #0d2b10;
        font-family: "Poppins-Medium";
      }
    }
  }
  .nav-resultados {
    h2 {
      color: white;
    }
  }
  .npagina {
    label {
      color: white !important;
    }
  }
  .nresultados {
    color: white;
  }
}
</style>
