<template>
  <div class="grupo-parametros">
    <div v-for="param in parametrosDeBusca" :key="param.key.toString()">
      <BotaoParametroBusca
        :label="param.label"
        :valor="param.valor"
        :onClick="(event) => onClick(event, param)"
      />
    </div>
  </div>
</template>

<script>
import { format, parseISO } from "date-fns";
import BotaoParametroBusca from "@/components/BotaoParametroBusca";

export default {
  name: "ParametrosBusca",
  props: {
    funcaoBuscaSimples: {
      type: Function,
      required: false,
    },
    funcaoBuscaAvancada: {
      type: Function,
      required: true,
    },
    termo: {
      type: String,
      required: false,
    },
    tipos: {
      type: Array,
      required: false,
    },
    tiposVeto: {
      type: Array,
      required: false,
    },
    tiposLegislacao: {
      type: Array,
      required: false,
    },
    tiposAto: {
      type: Array,
      required: false,
    },
    categorias: {
      type: Array,
      required: false,
    },
    orgaos: {
      type: Array,
      required: false,
    },
    filtro: {
      type: Object,
      required: true,
    },
    contexto: {
      type: String,
      required: true,
      validator: (value) =>
        [
          "legislacao",
          "ato_infralegal",
          "mensagem_veto",
          "ato_administrativo",
          "projeto_governador",
        ].includes(value),
    },
  },
  components: {
    BotaoParametroBusca,
  },
  data() {
    return {
      configuracoes: {
        legislacao: {
          labelsPermitidas: [
            "termo",
            "numero",
            "conteudo",
            "tipo_legislacao",
            "categoria_legislacao",
            "ementa",
            "orgao_emissor",
            "ano",
          ],
          labels: {
            termo: { label: "Termo", valor: (valor) => valor },
            numero: { label: "Número", valor: (valor) => valor },
            conteudo: { label: "Texto", valor: (valor) => valor },
            tipo_legislacao: {
              label: "Tipo",
              valor: (valor) => this.buscarNomeTipo(valor),
            },
            categoria_legislacao: {
              label: "Categoria",
              valor: (valor) => this.buscarNomeCategoria(valor),
            },
            orgao_emissor: {
              label: "Órgão Emissor",
              valor: (valor) => this.buscarNomeOrgao(valor),
            },
            ementa: { label: "Ementa", valor: (valor) => valor },
            ano: { label: "Ano", valor: (valor) => valor },
          },
          formatadores: {
            periodo_legislacao: { label: "Data da Legislação" },
            periodo_diario: { label: "Data do Diário" },
          },
        },
        ato_infralegal: {
          labelsPermitidas: [
            "termo",
            "numero",
            "conteudo",
            "tipo_ato_infralegal",
            "categoria_ato_infralegal",
            "ementa",
            "orgao_emissor",
            "ano",
          ],
          labels: {
            termo: { label: "Termo", valor: (valor) => valor },
            numero: { label: "Número", valor: (valor) => valor },
            conteudo: { label: "Texto", valor: (valor) => valor },
            tipo_ato_infralegal: {
              label: "Tipo",
              valor: (valor) => this.buscarNomeTipo(valor),
            },
            categoria_ato_infralegal: {
              label: "Categoria",
              valor: (valor) => this.buscarNomeCategoria(valor),
            },
            orgao_emissor: {
              label: "Órgão Emissor",
              valor: (valor) => this.buscarNomeOrgao(valor),
            },
            ementa: { label: "Ementa", valor: (valor) => valor },
            ano: { label: "Ano", valor: (valor) => valor },
          },
          formatadores: {
            periodo_data: { label: "Data do Ato Infralegal" },
            periodo_inicial_vigencia: { label: "Data de início da vigência" },
            periodo_final_vigencia: { label: "Data do fim da vigência" },
          },
        },
        mensagem_veto: {
          labelsPermitidas: [
            "tipo_veto",
            "tipo_legislacao",
            "texto",
            "numero",
            "ano",
          ],
          labels: {
            numero: { label: "Número", valor: (valor) => valor },
            texto: { label: "Texto", valor: (valor) => valor },
            tipo_veto: {
              label: "Tipo de Veto",
              valor: (valor) => this.buscarNomeTipoVeto(valor),
            },
            tipo_legislacao: {
              label: "Tipo de Legislação",
              valor: (valor) => this.buscarNomeTipoLegislacao(valor),
            },
            ano: { label: "Ano", valor: (valor) => valor },
          },
          formatadores: {
            periodo: { label: "Data do Ofício" },
          },
        },
        ato_administrativo: {
          labelsPermitidas: ["tipo_ato", "numero", "nome", "assunto", "ano"],
          labels: {
            numero: { label: "Número do ato", valor: (valor) => valor },
            ano: { label: "Ano", valor: (valor) => valor },
            tipo_ato: {
              label: "Tipo",
              valor: (valor) => this.buscarNomeTipoAto(valor),
            },
            nome: { label: "Nome", valor: (valor) => valor },
            assunto: { label: "Assunto", valor: (valor) => valor },
          },
          formatadores: {
            periodo_ato: { label: "Data do Ato" },
            periodo_diario: { label: "Data do Diário" },
          },
        },
        projeto_governador: {
          labelsPermitidas: [
            "numero_processo_projeto_lei",
            "assunto",
            "numero_oficio_msg",
            "numero_autografo_lei",
            "numero_lei",
            "em_tramitacao",
            "ano",
          ],
          labels: {
            numero_processo_projeto_lei: {
              label: "Nº do Projeto de Lei",
              valor: (valor) => valor,
            },
            assunto: { label: "Assunto", valor: (valor) => valor },
            ano: { label: "Ano", valor: (valor) => valor },
            numero_oficio_msg: {
              label: "Nº do Ofício mensagem",
              valor: (valor) => valor,
            },
            numero_autografo_lei: {
              label: "Nº do Autográfo de Lei",
              valor: (valor) => valor,
            },
            numero_lei: {
              label: "Nº Lei",
              valor: (valor) => valor,
            },
            em_tramitacao: {
              label: "Status andamento",
              valor: (valor) =>
                valor !== "false" && valor ? "Em tramitação" : "Finalizado",
            },
          },
          formatadores: {
            data_oficio: { label: "Data do Ofício" },
          },
        },
      },
      resetFiltro: false,
    };
  },
  computed: {
    configuracaoAtual() {
      return this.configuracoes[this.contexto];
    },
    parametrosDeBusca() {
      const { labelsPermitidas, labels, formatadores } = this.configuracaoAtual;

      const keys = Object.keys(this.$route.query).filter((key) =>
        labelsPermitidas.includes(key)
      );

      let parametros = [];

      for (let key of keys) {
        if (
          this.$route.query[key] != null &&
          this.$route.query[key] != undefined &&
          this.$route.query[key] !== "" &&
          labels[key]
        ) {
          parametros.push({
            label: labels[key].label,
            key,
            valor: labels[key].valor(this.$route.query[key]),
          });
        }
      }

      const formatData = (data) => format(parseISO(data), "dd/MM/yyyy");

      for (const [key, formatador] of Object.entries(formatadores)) {
        const periodoInicial =
          this.$route.query[`${key}_inicial`] ||
          this.$route.query[`${this.formatAndJoinText(key, "_inicial_")}`] ||
          this.$route.query[`${this.formatAndJoinText(key, "_inicio")}`];

        const periodoFinal =
          this.$route.query[`${key}_final`] ||
          this.$route.query[`${this.formatAndJoinText(key, "_final_")}`] ||
          this.$route.query[`${this.formatAndJoinText(key, "_fim")}`];

        if (periodoInicial && periodoFinal) {
          parametros.push({
            label: formatador.label,
            key: [`${key}_inicial`, `${key}_final`],
            valor: `${formatData(periodoInicial)} a ${formatData(
              periodoFinal
            )}`,
          });
        } else if (periodoInicial) {
          parametros.push({
            label: formatador.label,
            key: [`${key}_inicial`, `${key}_final`],
            valor: `A partir de ${formatData(periodoInicial)}`,
          });
        } else if (periodoFinal) {
          parametros.push({
            label: formatador.label,
            key: [`${key}_inicial`, `${key}_final`],
            valor: `Até ${formatData(periodoFinal)}`,
          });
        }
      }

      return parametros;
    },
  },
  methods: {
    onClick(event, parametro) {
      const keys = Array.isArray(parametro.key)
        ? parametro.key
        : [parametro.key];

      for (let key of keys) {
        if (
          Array.isArray(this.filtro[key]) ||
          key.includes("categoria") ||
          key.includes("tipo") ||
          key.includes("orgao")
        ) {
          this.resetFiltro = true;
        } else {
          this.$set(this.filtro, key, undefined);
        }
      }

      if (parametro.key === "termo") {
        this.$set(this.filtro, parametro.key, null);
        this.funcaoBuscaSimples(this.filtro.termo);
      } else {
        this.funcaoBuscaAvancada(event, this.resetFiltro);
      }
    },
    buscarNomeCategoria(valor) {
      const encontrarCategoria = (categorias, id) => {
        for (const categoria of categorias) {
          if (parseInt(categoria.id) === parseInt(id)) {
            return categoria.nome;
          }
          if (categoria.children && categoria.children.length > 0) {
            const resultado = encontrarCategoria(categoria.children, id);
            if (resultado) {
              return resultado;
            }
          }
        }
        return "";
      };

      return encontrarCategoria(this.categorias, valor);
    },
    buscarNomeOrgao(valor) {
      const encontrarOrgao = (orgaos, id) => {
        for (const orgao of orgaos) {
          if (parseInt(orgao.id) === parseInt(id)) {
            return orgao.nome;
          }
          if (orgao.children && orgao.children.length > 0) {
            const resultado = encontrarOrgao(orgao.children, id);
            if (resultado) {
              return resultado;
            }
          }
        }
        return "";
      };

      return encontrarOrgao(this.orgaos, valor);
    },
    buscarNomeTipo(valor) {
      const encontrarTipo = (tipos, id) => {
        for (const tipo of tipos) {
          if (parseInt(tipo.id) === parseInt(id)) {
            return tipo.nome;
          }
        }
        return "";
      };

      return encontrarTipo(this.tipos, valor);
    },
    buscarNomeTipoVeto(valor) {
      const encontrarTipo = (tipos, id) => {
        for (const tipo of tipos) {
          if (tipo[1] === parseInt(id)) {
            return tipo[0];
          }
        }
        return "";
      };

      return encontrarTipo(this.tiposVeto, valor);
    },
    buscarNomeTipoLegislacao(valor) {
      const encontrarTipo = (tipos, id) => {
        for (const tipo of tipos) {
          if (parseInt(tipo.id) === parseInt(id)) {
            return tipo.nome;
          }
        }
        return "";
      };

      return encontrarTipo(this.tiposLegislacao, valor);
    },
    buscarNomeTipoAto(valor) {
      const encontrarTipo = (tipos, id) => {
        for (const tipo of tipos) {
          if (tipo[1] === parseInt(id)) {
            return tipo[0];
          }
        }
        return "";
      };

      return encontrarTipo(this.tiposAto, valor);
    },
    formatData(data) {
      return format(parseISO(data), "dd/MM/yyyy");
    },
    formatAndJoinText(text, join) {
      if (join == "_inicial_" || join == "_final_") {
        let parts = text.split("_");
        if (parts.length > 1) {
          parts.splice(1, 0, join.slice(1, -1));
        }
        return parts.join("_");
      } else if (join == "_inicio" || join == "_fim") {
        return text + join;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.grupo-parametros {
  margin: 0.5rem 0rem -0.5rem;
  gap: 1rem;
}
div {
  display: flex;
  flex-wrap: wrap;
  margin: 0rem 0rem 0.2rem 0.2rem;
}
@media screen and (min-width: 64rem) {
  .grupo-parametros {
    margin-top: 1.5rem;
  }
}
</style>
