<template>
  <div class="tipo">
    <div class="centro">
      <h1>Consulta por Categoria de Legislação</h1>
      <BuscaSimplesCards titulo="Consultas Rápidas" :cards="cardsLegislacao" />
      <ModalAjuda :texto="textoModal" />
      <Loading v-if="carregando" />
      <ErroResultados v-if="!carregando && erro" />
      <PainelBuscaSimplificada
        :dados="categoriasFormatados"
        :generateUrl="generateUrl"
        :generateUrlSolo="generateUrlSolo"
        titulo="Categorias de Legislação"
        v-if="!carregando && !erro"
      />
    </div>
  </div>
</template>

<script>
import LegislacaoApi from "../../services/LegislacaoApi";
import Loading from "../../components/Loading";
import ErroResultados from "../../components/ErroResultados";
import PainelBuscaSimplificada from "../../components/PainelBuscaSimplificada";
import { mixinLegislacao } from "../../mixins/mixinLegislacao";

import metaDados from "../../helpers/metaDados";
import ModalAjuda from "../../components/ModalAjuda.vue";

export default {
  name: "PorCategoria",
  metaInfo() {
    return {
      title: "Pesquisa de Legislação por Categoria",
      titleTemplate: "%s - Casa Civil do Estado de Goiás",
      meta: [
        {
          vmid: "description",
          name: "description",
          property: "description",
          content:
            "Página de acesso e pesquisa a toda legislação e atos normativos do Governo do Estado de Goiás.",
        },
        {
          vmid: "og:description",
          property: "og:description",
          content:
            "Página de acesso e pesquisa a toda legislação e atos normativos do Governo do Estado de Goiás.",
        },
        {
          vmid: "og:title",
          property: "og:title",
          content:
            "Pesquisa a Legislação por Categoria - Casa Civil do Estado de Goiás",
        },
        {
          vmid: "og:type",
          property: "og:type",
          content: "article",
        },
        {
          vmid: "twitter:description",
          name: "twitter:description",
          property: "twitter:description",
          content:
            "Página de acesso e pesquisa a toda legislação e atos normativos do Governo do Estado de Goiás.",
        },
        {
          vmid: "twitter:title",
          name: "twitter:title",
          property: "twitter:title",
          content:
            "Pesquisa a Legislação por Categoria - Casa Civil do Estado de Goiás",
        },
        ...metaDados(this.$route),
      ],
    };
  },
  mixins: [mixinLegislacao],
  data() {
    return {
      carregando: true,
      erro: false,
      categorias: [],
      filtro: {
        categoria_legislacao: [],
        ano: null,
      },
      textoModal: {
        titulo: "Como funciona a contagem de legislações?",
        descricao:
          "As legislações são organizadas em categorias e subcategorias hierárquicas. Cada legislação pode pertencer a mais de uma subcategoria, mas o total geral considera <strong>legislações distintas</strong> (sem duplicação).",
        descricaoExemplo:
          "Considere a categoria 'Acesso à Informação', que possui as subcategorias 'Transparência', 'Proteção de Dados', entre outras.",
        textoExemplo:
          "<ul><li>Uma legislação que trata de <strong>transparência</strong> e <strong>proteção de dados</strong> será contada <strong>uma vez</strong> no total geral de 'Acesso à Informação', mas será exibida e computada nas <strong>duas subcategorias</strong>.</li></ul> Contagem: <ul><li>Quantidade total (sem repetições): 53 legislações</li></ul> <ul><li>Soma das subcategorias: 55 legislações <i>(a diferença ocorre porque há legislações em mais de uma subcategoria)</i></li></ul>",
        complemento:
          "Se uma categoria tiver legislações próprias que <strong>não pertencem a nenhuma subcategoria</strong>, elas aparecerão na seção 'Outras'. Isso ajuda a identificar legislações que não se encaixam nas divisões mais específicas.",
      },
    };
  },
  computed: {
    // Propriedade Computada - Parte para formatar os números com separador milhar.
    categoriasFormatados() {
      return this.categorias.map((categoria) => {
        return {
          ...categoria,
          quantidade: categoria.quantidade.toLocaleString("pt-BR"),
          quantidade_total: categoria.quantidade_total.toLocaleString("pt-BR"),
        };
      });
    },
  },
  components: {
    PainelBuscaSimplificada,
    Loading,
    ErroResultados,
    ModalAjuda,
  },
  created() {
    this.legislacaoApi = new LegislacaoApi();

    this.carregarCategorias();
  },
  methods: {
    carregarCategorias: async function () {
      try {
        if (this.legislacaoApi.cancelTokens.getCategorias) {
          this.legislacaoApi.cancelTokens.getCategorias.cancel();
        }

        this.carregando = true;
        this.erro = false;
        this.categorias = await this.legislacaoApi.getCategorias();
        this.carregando = false;
      } catch (err) {
        if (this.legislacaoApi.isCancel(err)) {
          return;
        }
        console.log(err);
        this.erro = true;
        this.carregando = false;
      }
    },
    generateUrl(ano, dados) {
      this.filtro.categoria_legislacao.push(dados.id);
      this.filtro.ano = ano;

      this.$router.push({
        name: "PesquisaLegislacao",
        query: {
          ...this.filtro,
          pagina: 1,
          qtd_por_pagina: this.qtd_por_pagina,
          busca_avancada: 1,
        },
      });
    },
    generateUrlSolo(ano, dados) {
      this.filtro.categoria_legislacao = dados.id;
      this.filtro.ano = ano;

      this.$router.push({
        name: "PesquisaLegislacao",
        query: {
          ...this.filtro,
          pagina: 1,
          qtd_por_pagina: this.qtd_por_pagina,
          busca_avancada: 1,
          solo: true,
        },
      });
    },
    formatNumber(number) {
      // Método para formatar os números com separador milhar.
      return number.toLocaleString("pt-BR");
    },
  },
};
</script>

<style lang="scss" scoped>
h1 {
  font-family: "Poppins-Bold";
  color: #2c3f2b;
  padding-left: 0.5rem;
  border-left: #ffa900 solid 0.15rem;
  margin: 2rem 0rem 1.5rem 0.5rem;
  font-size: 1.5rem;
}
.tipo {
  min-height: calc(100vh - 10rem);
  margin-bottom: 5rem;
}

p {
  margin: 1rem 1.5rem 1rem 0.5rem;
  font-size: 0.9rem;
  letter-spacing: 0.03rem;
  strong {
    text-transform: uppercase;
  }
}
</style>
