<template>
  <div>
    <div class="centro">
      <h1>Ato Infralegal</h1>
      <BuscaSimplesCards
        titulo="Consultas Rápidas"
        :cards="cardsAtoInfralegal"
      />
    </div>
    <div
      class="pesquise"
      v-show="!carregando && !erro && !carregandoFiltros && !erroFiltros"
    >
      <div class="centro">
        <div>
          <h2>Pesquise aqui:</h2>
          <div class="item row1">
            <BuscaSimples
              v-model="termo"
              contexto="do ato infralegal"
              @busca-simples="fazerBuscaSimples"
            />
          </div>
          <OperadoresBusca @adicionar-operador="adicionarOperador" />
          <div class="botao">
            <button type="button" @click="tooglePesquisaAvancada">
              Pesquisa avançada
              <span>
                <template v-if="!exibirPesquisaAvancada">&#9660;</template>
                <template v-else>&#9650;</template>
              </span>
            </button>
          </div>
          <transition name="slide">
            <form v-if="exibirPesquisaAvancada" @submit="fazerBuscaAvancada">
              <div class="avancado">
                <div class="row2">
                  <div class="item">
                    <label>Tipo</label>
                    <multiselect
                      v-model="tipoSelecionado"
                      :options="tipos_atos_infralegais"
                      :multiple="false"
                      :taggable="true"
                      :searchable="true"
                      :show-labels="false"
                      placeholder="Escolha opções..."
                      label="nome"
                      track-by="id"
                    ></multiselect>
                  </div>
                  <div class="row2mobile">
                    <div class="item">
                      <label>Nº do Ato Infralegal</label>
                      <input type="text" v-model="filtro.numero" />
                    </div>
                    <div class="item">
                      <label>Ano do Ato Infralegal</label>
                      <input type="number" v-model="filtro.ano" />
                    </div>
                  </div>
                </div>
                <div class="row2">
                  <div class="grupo">
                    <p>Data do Ato Infralegal</p>
                    <div class="data">
                      <div class="item">
                        <label>De</label>
                        <date-picker
                          v-model="filtro.periodo_inicial_data"
                          :formatter="datepickerFormatter"
                        ></date-picker>
                      </div>
                      <div class="item">
                        <label>Até</label>
                        <date-picker
                          v-model="filtro.periodo_final_data"
                          :formatter="datepickerFormatter"
                        ></date-picker>
                      </div>
                    </div>
                  </div>
                  <div class="grupo">
                    <p>Data de início da vigência</p>
                    <div class="data">
                      <div class="item">
                        <label>De</label>
                        <date-picker
                          v-model="filtro.periodo_inicial_vigencia_inicio"
                          :formatter="datepickerFormatter"
                        ></date-picker>
                      </div>
                      <div class="item">
                        <label>Até</label>
                        <date-picker
                          v-model="filtro.periodo_inicial_vigencia_fim"
                          :formatter="datepickerFormatter"
                        ></date-picker>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row2">
                  <div class="grupo">
                    <p>Data do fim da vigência</p>
                    <div class="data">
                      <div class="item">
                        <label>De</label>
                        <date-picker
                          v-model="filtro.periodo_final_vigencia_inicio"
                          :formatter="datepickerFormatter"
                        ></date-picker>
                      </div>
                      <div class="item">
                        <label>Até</label>
                        <date-picker
                          v-model="filtro.periodo_final_vigencia_fim"
                          :formatter="datepickerFormatter"
                        ></date-picker>
                      </div>
                    </div>
                  </div>
                  <div class="item">
                    <label>Órgão Emissor</label>
                    <multiselect
                      v-model="orgaoSelecionado"
                      :options="orgaos_atos_infralegais"
                      :multiple="false"
                      :taggable="true"
                      :searchable="true"
                      :show-labels="false"
                      placeholder="Escolha opções..."
                      label="nome"
                      track-by="id"
                    ></multiselect>
                  </div>
                </div>
                <div class="row2">
                  <div class="item">
                    <label>Texto</label>
                    <input type="text" v-model="filtro.conteudo" />
                  </div>
                </div>
                <div class="row2">
                  <div class="item">
                    <label>Ementa</label>
                    <input type="text" v-model="filtro.ementa" />
                  </div>
                  <div class="item"></div>
                  <div></div>
                  <div class="botoes">
                    <button
                      title="Limpar"
                      @click="limparFiltro()"
                      type="button"
                    >
                      Limpar
                    </button>
                    <button type="submit">Buscar</button>
                  </div>
                </div>
              </div>
            </form>
          </transition>
        </div>
        <div class="seta">
          <img
            src="../../assets/images/triangulo-form.png"
            class="triangulo t-branco"
            alt=" "
          />
          <img
            src="../../assets/images/triangulo-form-black.png"
            class="triangulo t-preto"
            alt=" "
          />
        </div>
      </div>
    </div>
    <div class="centro campo-resultados" id="resultados">
      <div class="nav-resultados">
        <h2>Resultados da Pesquisa</h2>
        <div
          class="dados-abertos"
          v-show="!carregando && !erro && !carregandoFiltros && !erroFiltros"
        >
          <p>
            <span>Dados Abertos:</span>
            <a @click="abrirDadosAbertos('json')">JSON</a>
            <a @click="abrirDadosAbertos('csv')">CSV</a>
          </p>
        </div>
        <ParametrosBusca
          :funcaoBuscaSimples="fazerBuscaSimples"
          :funcaoBuscaAvancada="fazerBuscaAvancada"
          :termo="termo"
          :tipos="tipos_atos_infralegais"
          :orgaos="orgaos_atos_infralegais"
          :filtro="filtro"
          contexto="ato_infralegal"
          v-show="!carregando && !erro && !carregandoFiltros && !erroFiltros"
        />
        <div class="npagina" v-show="!carregando && !carregandoFiltros">
          <label>Resultados por Página</label>
          <select :value="qtd_por_pagina" @input="mudouQtdPagina">
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        </div>
        <div
          class="nresultados"
          v-show="!carregando && !erro && !carregandoFiltros && !erroFiltros"
        >
          <p>
            <strong>{{ totalResultadosTexto }}</strong>
            {{
              totalResultadosTexto === 1
                ? ` resultado encontrado`
                : ` resultados encontrados`
            }}
          </p>
        </div>
      </div>
      <div>
        <TabelaResultadosAtoInfralegal
          :atosInfralegais="atosInfralegais"
          v-if="!carregando && !carregandoFiltros && !erro && !erroFiltros"
        ></TabelaResultadosAtoInfralegal>

        <Loading v-if="carregando" size="medium"></Loading>
        <ErroResultados
          v-if="!carregando && !carregandoFiltros && (erro || erroFiltros)"
        ></ErroResultados>

        <paginate
          v-if="!erro && !carregando && !carregandoFiltros && !erroFiltros"
          v-model="pagina"
          :page-count="pageCount"
          :click-handler="mudouPagina"
          :prev-text="'Anterior'"
          :next-text="'Próxima'"
          :container-class="'className'"
          active-class="active-page"
        ></paginate>
      </div>
    </div>
  </div>
</template>

<script>
// Bibliotecas
import { format, parseISO } from "date-fns";
import Paginate from "vuejs-paginate";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import "vue2-datepicker/locale/pt-br";
import Multiselect from "vue-multiselect";

// Componentes
import TabelaResultadosAtoInfralegal from "../../components/pesquisa_ato_infralegal/TabelaResultadosAtoInfralegal";
import Loading from "../../components/Loading";
import ErroResultados from "../../components/ErroResultados";
import ParametrosBusca from "../../components/ParametrosBusca";
import BuscaSimples from "../../components/BuscaSimples.vue";
import OperadoresBusca from "../../components/OperadoresBusca.vue";

// API, Mixins e helpers
import AtoInfralegalApi from "../../services/AtoInfralegalApi";
import TipoAtoInfralegalApi from "../../services/TipoAtoInfralegalApi";
import CategoriaAtoInfralegalApi from "../../services/CategoriaAtoInfralegalApi";
import OrgaoAtoInfralegalApi from "../../services/OrgaoAtoInfralegalApi";
import { datepickerFormatter } from "../../helpers/datepickerFormatter";
import { mixinAtoInfralegal } from "../../mixins/mixinAtoInfralegal";
import tryParseInt from "../../helpers/tryParseInt";
import metaDados from "../../helpers/metaDados";

export default {
  name: "PesquisaAtoInfralegal",
  metaInfo() {
    return {
      title: "Pesquisa de Ato Infralegal",
      titleTemplate: "%s - Casa Civil do Estado de Goiás",
      meta: [
        {
          vmid: "description",
          name: "description",
          property: "description",
          content:
            "Página de acesso e pesquisa a toda legislação e atos normativos do Governo do Estado de Goiás.",
        },
        {
          vmid: "og:description",
          property: "og:description",
          content:
            "Página de acesso e pesquisa a toda legislação e atos normativos do Governo do Estado de Goiás.",
        },
        {
          vmid: "og:title",
          property: "og:title",
          content: "Pesquisa a Legislação - Casa Civil do Estado de Goiás",
        },
        {
          vmid: "twitter:description",
          name: "twitter:description",
          property: "twitter:description",
          content:
            "Página de acesso e pesquisa a toda legislação e atos normativos do Governo do Estado de Goiás.",
        },
        {
          vmid: "twitter:title",
          name: "twitter:title",
          property: "twitter:title",
          content: "Pesquisa a Legislação - Casa Civil do Estado de Goiás",
        },
        ...metaDados(this.$route),
      ],
    };
  },
  mixins: [mixinAtoInfralegal],
  data() {
    return {
      atosInfralegais: [],
      maisRecentes: [],
      carregandoMaisRecentes: true,
      erroMaisRecentes: false,
      carregando: true,
      erro: false,
      totalResultados: null,
      totalResultadosTexto: null,
      pagina: 1,
      qtd_por_pagina: 10,
      termo: null,
      filtro: {
        numero: null,
        conteudo: null,
        tipo_ato_infralegal: null,
        orgao_emissor: null,
        ano: null,
        periodo_inicial_data: null,
        periodo_final_data: null,
        periodo_inicial_vigencia_inicio: null,
        periodo_final_vigencia_inicio: null,
        periodo_inicial_vigencia_fim: null,
        periodo_final_vigencia_fim: null,
        ementa: null,
      },
      carregandoFiltros: true,
      erroFiltros: false,
      tipos_atos_infralegais: [],
      tipoSelecionado: [],
      orgaos_atos_infralegais: [],
      orgaos_atos_infralegais_filtrados: [],
      orgaoSelecionado: null,
      exibirPesquisaAvancada: tryParseInt(this.getParam("busca_avancada")) == 1,
      datepickerFormatter,
    };
  },
  components: {
    TabelaResultadosAtoInfralegal,
    Paginate,
    DatePicker,
    Multiselect,
    Loading,
    ErroResultados,
    ParametrosBusca,
    OperadoresBusca,
    BuscaSimples,
  },
  created() {
    this.atoInfralegalApi = new AtoInfralegalApi();

    this.tipoAtoInfralegalApi = new TipoAtoInfralegalApi();
    this.categoriaAtoInfralegalApi = new CategoriaAtoInfralegalApi();
    this.orgaoAtoInfralegalApi = new OrgaoAtoInfralegalApi();

    this.carregarQtdPorPagina();
    this.carregarValoresFiltro();
    this.carregarAtoInfralegal();
  },
  watch: {
    $route: function (to, from) {
      const mudouQuery =
        to.query.numero != from.query.numero ||
        to.query.conteudo != from.query.conteudo ||
        to.query.tipo_ato_infralegal != from.query.tipo_ato_infralegal ||
        to.query.categoria_ato_infralegal !=
          from.query.categoria_ato_infralegal ||
        to.query.ano != from.query.ano ||
        to.query.orgao_emissor != from.query.orgao_emissor ||
        to.query.periodo_inicial_data != from.query.periodo_inicial_data ||
        to.query.periodo_final_data != from.query.periodo_final_data ||
        to.query.periodo_inicial_vigencia_inicio !=
          from.query.periodo_inicial_vigencia_inicio ||
        to.query.periodo_inicial_vigencia_fim !=
          from.query.periodo_inicial_vigencia_fim ||
        to.query.periodo_final_vigencia_inicio !=
          from.query.periodo_final_vigencia_inicio ||
        to.query.periodo_final_vigencia_fim !=
          from.query.periodo_final_vigencia_fim ||
        to.query.pagina != from.query.pagina ||
        to.query.qtd_por_pagina != from.query.qtd_por_pagina ||
        to.query.termo != from.query.termo ||
        to.query.ementa != from.query.ementa;

      if (
        from.name == "PesquisaAtoInfralegal" &&
        to.name == "PesquisaAtoInfralegal" &&
        mudouQuery
      ) {
        this.carregarValoresFiltro();
        this.carregarAtoInfralegal();
      }
    },
  },
  methods: {
    adicionarOperador(operador) {
      if (this.termo == null) {
        this.termo = "";
      }
      this.termo += ` ${operador} `;
    },
    mudouQtdPagina: function (event) {
      const novaQtdPagina = parseInt(event.target.value);
      if (novaQtdPagina < this.qtd_por_pagina) {
        this.qtd_por_pagina = novaQtdPagina;
        this.mudouPagina(this.pagina);
      } else {
        let novaPagina = Math.ceil(
          (this.qtd_por_pagina * this.pagina) / novaQtdPagina
        );

        this.qtd_por_pagina = novaQtdPagina;
        this.mudouPagina(novaPagina);
      }
    },
    getParam: function (name) {
      if (this.$route && this.$route.query) {
        return this.$route.query[name];
      }
      return null;
    },
    fazerBuscaSimples: function (termo) {
      this.$router.push({
        name: "PesquisaAtoInfralegal",
        query: {
          termo: termo,
          pagina: 1,
          qtd_por_pagina: this.qtd_por_pagina,
        },
      });
      window.location.reload();

      this.$scrollTo("#resultados", 700, {
        offset: -100,
        cancelable: false,
      });
    },
    fazerBuscaAvancada: function (event, flag) {
      event.preventDefault();

      if (flag == true) {
        this.filtro.tipo_ato_infralegal = null;
        this.tipoSelecionado = null;
        this.filtro.orgao_emissor = null;
        this.orgaoSelecionado = null;
      } else {
        this.tipoSelecionado
          ? (this.filtro.tipo_ato_infralegal = this.tipoSelecionado.id)
          : (this.filtro.tipo_ato_infralegal = null);

        this.orgaoSelecionado
          ? (this.filtro.orgao_emissor = this.orgaoSelecionado.id)
          : (this.filtro.orgao_emissor = null);
      }

      this.$router.push({
        name: "PesquisaAtoInfralegal",
        query: {
          ...this.filtro,
          ...this.convertePeriodosDatasParaString(),
          pagina: 1,
          qtd_por_pagina: this.qtd_por_pagina,
          busca_avancada: 1,
        },
      });
      this.$scrollTo("#resultados", 700, {
        offset: -100,
        cancelable: false,
      });
    },
    mudouPagina: function (page) {
      this.pagina = page;

      let termo = {};
      if (this.getParam("termo")) {
        termo = { termo: this.getParam("termo") };
      }

      const filtro = {
        numero: this.getParam("numero"),
        conteudo: this.getParam("conteudo"),
        tipo_ato_infralegal: this.getParam("tipo_ato_infralegal"),
        categoria_ato_infralegal: this.getParam("categoria_ato_infralegal"),
        orgao_emissor: this.getParam("orgao_emissor"),
        ano: this.getParam("ano"),
        periodo_inicial_data: this.getParam("periodo_inicial_data"),
        periodo_final_data: this.getParam("periodo_final_data"),
        periodo_inicial_vigencia_inicio: this.getParam(
          "periodo_inicial_vigencia_inicio"
        ),
        periodo_inicial_vigencia_fim: this.getParam(
          "periodo_inicial_vigencia_fim"
        ),
        periodo_final_vigencia_inicio: this.getParam(
          "periodo_final_vigencia_inicio"
        ),
        periodo_final_vigencia_fim: this.getParam("periodo_final_vigencia_fim"),
        ementa: this.getParam("ementa"),
      };

      this.$router.push({
        name: "PesquisaAtoInfralegal",
        query: {
          ...filtro,
          ...termo,
          pagina: this.pagina,
          qtd_por_pagina: this.qtd_por_pagina,
          busca_avancada: this.getParam("busca_avancada"),
        },
      });
      this.$scrollTo("#resultados", 0, {
        offset: -100,
        cancelable: false,
      });
    },
    carregarValoresFiltro: async function () {
      try {
        if (
          !this.carregandoFiltro &&
          !this.erroFiltro &&
          this.tipos_atos_infralegais &&
          this.tipos_atos_infralegais.length > 0
        ) {
          return;
        }

        this.carregandoFiltros = true;
        this.erroFiltros = false;

        this.tipos_atos_infralegais =
          await this.tipoAtoInfralegalApi.getTiposAtoInfralegal();

        if (this.filtro.tipo_ato_infralegal) {
          const filtros = Array.isArray(this.filtro.tipo_ato_infralegal)
            ? this.filtro.tipo_ato_infralegal
            : [this.filtro.tipo_ato_infralegal];

          this.tipoSelecionado = this.tipos_atos_infralegais.filter((tipo) =>
            filtros.some((filtro_tipo) => filtro_tipo == tipo.id)
          );
        }

        var orgaos = await this.orgaoAtoInfralegalApi.getOrgaosAtoInfralegal();
        this.orgaos_atos_infralegais = orgaos;
        this.orgaos_atos_infralegais_filtrados = this.orgaos_atos_infralegais;

        if (this.filtro.orgao_emissor) {
          const filtros = Array.isArray(this.filtro.orgao_emissor)
            ? this.filtro.orgao_emissor
            : [this.filtro.orgao_emissor];

          const selecionados = this.buscarOrgaos(
            this.orgaos_atos_infralegais_filtrados,
            filtros
          );

          this.orgaoSelecionado =
            selecionados.length > 0 ? selecionados[0] : null;
        }

        this.carregandoFiltros = false;
      } catch (err) {
        console.log("carregarValoresFiltro", err);
        this.carregandoFiltros = false;
        this.erroFiltros = true;
        this.tipos_atos_infralegais = null;
        this.orgaos_atos_infralegais = null;
        this.orgaos_atos_infralegais_filtradas = null;
      }
    },
    buscarOrgaos(orgaos, filtros) {
      let selecionados = [];

      orgaos.forEach((orgao) => {
        if (filtros.some((filtro_orgao) => filtro_orgao == orgao.id)) {
          selecionados.push(orgao);
        }

        if (orgao.children && Array.isArray(orgao.children)) {
          selecionados = selecionados.concat(
            this.buscarOrgaos(orgao.children, filtros)
          );
        }
      });

      return selecionados;
    },
    getLabelItem: function (item) {
      if (item && !item.length) {
        return item.nome;
      } else if (item && item.length) {
        item.map((elemento) => {
          return elemento.nome;
        });
      }
    },
    onOrgaoSelecionado(orgao) {
      this.orgaoSelecionado = orgao;
      this.filtro.orgao_emissor = orgao.id;
    },
    carregarAtoInfralegal: async function () {
      try {
        this.carregando = true;
        this.erro = false;

        this.qtd_por_pagina =
          this.getParam("qtd_por_pagina") || this.qtd_por_pagina;

        this.armazenarQtdPorPagina();

        const pagina = this.getParam("pagina") || 1;
        this.pagina = parseInt(pagina);

        this.termo = this.getParam("termo");

        this.filtro = {
          numero: this.getParam("numero"),
          conteudo: this.getParam("conteudo"),
          tipo_ato_infralegal: this.getParam("tipo_ato_infralegal"),
          categoria_ato_infralegal: this.getParam("categoria_ato_infralegal"),
          orgao_emissor: this.getParam("orgao_emissor"),
          ano: this.getParam("ano"),
          ementa: this.getParam("ementa"),
          ...this.convertPeriodosStringParaDatas(),
        };

        const buscaAvancada = tryParseInt(this.getParam("busca_avancada"));

        if (this.atoInfralegalApi.cancelTokens.getAtosInfralegais) {
          this.atoInfralegalApi.cancelTokens.getAtosInfralegais.cancel();
        }

        let data;
        if (buscaAvancada && buscaAvancada === 1) {
          data = await this.atoInfralegalApi.getAtosInfralegais({
            ...this.filtro,
            periodo_inicial_data: this.getParam("periodo_inicial_data"),
            periodo_final_data: this.getParam("periodo_final_data"),
            periodo_inicial_vigencia_inicio: this.getParam(
              "periodo_inicial_vigencia_inicio"
            ),
            periodo_inicial_vigencia_fim: this.getParam(
              "periodo_inicial_vigencia_fim"
            ),
            periodo_final_vigencia_inicio: this.getParam(
              "periodo_final_vigencia_inicio"
            ),
            periodo_final_vigencia_fim: this.getParam(
              "periodo_final_vigencia_fim"
            ),
            qtd_por_pagina: this.qtd_por_pagina,
            page: this.pagina,
          });
        } else {
          data = await this.atoInfralegalApi.getAtosInfralegais({
            termo: this.termo,
            qtd_por_pagina: this.qtd_por_pagina,
            page: this.pagina,
          });
        }

        this.atosInfralegais = data.resultados;
        this.totalResultados = data.total_resultados;
        this.totalResultadosTexto = data.total_resultados.toLocaleString();
        this.carregando = false;
      } catch (err) {
        if (this.atoInfralegalApi.isCancel(err)) {
          return;
        }
        console.log("carregarAtoInfralegal", err);
        this.carregando = false;
        this.erro = true;
      }
    },
    limparFiltro: function () {
      this.filtro = {
        numero: null,
        conteudo: null,
        tipo_ato_infralegal: undefined,
        categoria_ato_infralegal: undefined,
        orgao_emissor: undefined,
        ementa: null,
        autor: null,
        ano: null,
        periodo_inicial_data: null,
        periodo_final_data: null,
        periodo_inicial_vigencia_inicio: null,
        periodo_final_vigencia_inicio: null,
        periodo_inicial_vigencia_fim: null,
        periodo_final_vigencia_fim: null,
      };
      this.tipoSelecionado = null;
      this.orgaoSelecionado = null;
    },
    convertePeriodosDatasParaString: function () {
      return {
        periodo_inicial_data: this.filtro.periodo_inicial_data
          ? format(this.filtro.periodo_inicial_data, "yyyy-MM-dd")
          : null,
        periodo_final_data: this.filtro.periodo_final_data
          ? format(this.filtro.periodo_final_data, "yyyy-MM-dd")
          : null,
        periodo_inicial_vigencia_inicio: this.filtro
          .periodo_inicial_vigencia_inicio
          ? format(this.filtro.periodo_inicial_vigencia_inicio, "yyyy-MM-dd")
          : null,
        periodo_final_vigencia_inicio: this.filtro.periodo_final_vigencia_inicio
          ? format(this.filtro.periodo_final_vigencia_inicio, "yyyy-MM-dd")
          : null,
        periodo_inicial_vigencia_fim: this.filtro.periodo_inicial_vigencia_fim
          ? format(this.filtro.periodo_inicial_vigencia_fim, "yyyy-MM-dd")
          : null,
        periodo_final_vigencia_fim: this.filtro.periodo_final_vigencia_fim
          ? format(this.filtro.periodo_final_vigencia_fim, "yyyy-MM-dd")
          : null,
      };
    },
    convertPeriodosStringParaDatas: function () {
      const periodo_inicial_data = this.getParam("periodo_inicial_data");
      const periodo_final_data = this.getParam("periodo_final_data");
      const periodo_inicial_vigencia_inicio = this.getParam(
        "periodo_inicial_vigencia_inicio"
      );
      const periodo_final_vigencia_inicio = this.getParam(
        "periodo_final_vigencia_inicio"
      );
      const periodo_inicial_vigencia_fim = this.getParam(
        "periodo_inicial_vigencia_fim"
      );
      const periodo_final_vigencia_fim = this.getParam(
        "periodo_final_vigencia_fim"
      );

      return {
        periodo_inicial_data: periodo_inicial_data
          ? parseISO(periodo_inicial_data)
          : null,
        periodo_final_data: periodo_final_data
          ? parseISO(periodo_final_data)
          : null,
        periodo_inicial_vigencia_inicio: periodo_inicial_vigencia_inicio
          ? parseISO(periodo_inicial_vigencia_inicio)
          : null,
        periodo_final_vigencia_inicio: periodo_final_vigencia_inicio
          ? parseISO(periodo_final_vigencia_inicio)
          : null,
        periodo_inicial_vigencia_fim: periodo_inicial_vigencia_fim
          ? parseISO(periodo_inicial_vigencia_fim)
          : null,
        periodo_final_vigencia_fim: periodo_final_vigencia_fim
          ? parseISO(periodo_final_vigencia_fim)
          : null,
      };
    },
    tooglePesquisaAvancada: function () {
      this.exibirPesquisaAvancada = !this.exibirPesquisaAvancada;
    },
    abrirDadosAbertos: function (format) {
      if (
        this.totalResultados == undefined ||
        this.totalResultados == null ||
        this.totalResultados > 1000
      ) {
        alert(
          "O download dos dados é limitado a 1000 registros. Refaça sua consulta utilizando os filtros disponíveis."
        );
      } else {
        const params = new URLSearchParams({
          numero: this.getParam("numero") || "",
          conteudo: this.getParam("conteudo") || "",
          tipo_ato_infralegal: this.getParam("tipo_ato_infralegal") || "",
          categoria_ato_infralegal:
            this.getParam("categoria_ato_infralegal") || "",
          orgao_emissor: this.getParam("orgao_emissor") || "",
          ano: this.getParam("ano") || "",
          periodo_inicial_data: this.getParam("periodo_inicial_data") || "",
          periodo_final_data: this.getParam("periodo_final_data") || "",
          periodo_inicial_vigencia_inicio:
            this.getParam("periodo_inicial_vigencia_inicio") || "",
          periodo_final_vigencia_inicio:
            this.getParam("periodo_final_vigencia_inicio") || "",
          periodo_inicial_vigencia_fim:
            this.getParam("periodo_inicial_vigencia_fim") || "",
          periodo_final_vigencia_fim:
            this.getParam("periodo_final_vigencia_fim") || "",
          termo: this.getParam("termo") || "",
          ementa: this.getParam("ementa") || "",
        }).toString();
        window.open(
          `${process.env.VUE_APP_BASE_API_URL}/api/v1/pesquisa/atos_infralegais/dados_abertos.${format}?${params}`,
          "_blank"
        );
      }
    },
    armazenarQtdPorPagina() {
      localStorage.setItem(
        "qtd_por_pagina_ato_infralegal",
        this.qtd_por_pagina
      );
    },
    carregarQtdPorPagina() {
      const qtd = localStorage.getItem("qtd_por_pagina_ato_infralegal");
      if (qtd) {
        this.qtd_por_pagina = tryParseInt(qtd);
      }
    },
  },
  computed: {
    pageCount: function () {
      if (
        this.totalResultados != undefined &&
        this.totalResultados != null &&
        this.qtd_por_pagina != null &&
        this.qtd_por_pagina != undefined &&
        this.totalResultados > 0 &&
        this.qtd_por_pagina > 0
      ) {
        return Math.ceil(this.totalResultados / this.qtd_por_pagina);
      }
      return 0;
    },
    orgaosFormatados() {
      const flattenOrgaos = (orgaos) => {
        return orgaos;
      };

      return flattenOrgaos(this.orgaos_atos_infralegais_filtrados);
    },
  },
};
</script>

<style lang="scss" scoped>
.busca-info {
  display: block;
  margin: 0 0 1rem 0;
  padding: 0;
  .info-texto {
    margin: 0.5rem 0;
    color: #ffa900;
    font-size: 0.93rem;
    span {
      font-weight: bold;
    }
  }
}
.tooltip {
  display: flex;
  width: max-content;
  margin: 1rem 0 1rem 0;
  .button {
    margin: 0.3rem 0.7rem 0 0;
    font-size: 0.9rem;
    background-color: #edf2ee;
    border: none;
    border-radius: 0.3rem;
    padding: 0.3rem 0.5rem;
    box-shadow: 1px 2px 1px #ffa900;
    cursor: pointer;
  }
  .button:hover {
    box-shadow: 0px 0px 0px;
    padding: 0.27rem 0.47rem;
    font-size: 0.87rem;
    background-color: #ffa900;
  }
  .container-tooltip {
    position: relative;
  }
  .modal {
    display: block;
    width: 16rem;
    z-index: 1000;
    position: absolute;
    max-width: 20rem;
    font-size: 0.9rem;
    background-color: #edf2ee;
    box-shadow: 3px 5px 5px #ffa900;
    padding: 0.3rem 1rem;
    border-radius: 0.5rem;

    span {
      font-weight: bold;
    }
  }
  .modal-1 {
    display: none;
  }
  .modal-2 {
    display: none;
  }
  .modal-3 {
    display: none;
  }
  .modal-4 {
    display: none;
  }
}

@media screen and (min-width: 320px) and (max-width: 425px) {
  .modal-1 {
    left: -0.5rem;
    top: 5.5vh;

    &::after {
      content: "";

      width: 0;
      height: 0;

      position: absolute;

      border-left: 20px solid transparent;
      border-right: 20px solid transparent;
      border-bottom: 20px solid #edf2ee;

      top: -4%;
      left: 0%;
    }
  }
  .modal-2 {
    left: -2.5rem;
    top: 5.5vh;

    &::after {
      content: "";

      width: 0;
      height: 0;

      position: absolute;

      border-left: 20px solid transparent;
      border-right: 20px solid transparent;
      border-bottom: 20px solid #edf2ee;

      top: -4%;
      left: 13%;
    }
  }
  .modal-3 {
    left: -5.5rem;
    top: 5.5vh;

    &::after {
      content: "";

      width: 0;
      height: 0;

      position: absolute;

      border-left: 20px solid transparent;
      border-right: 20px solid transparent;
      border-bottom: 20px solid #edf2ee;

      top: -4%;
      left: 31%;
    }
  }
  .modal-4 {
    left: -9rem;
    top: 5.5vh;

    &::after {
      content: "";

      width: 0;
      height: 0;

      position: absolute;

      border-left: 20px solid transparent;
      border-right: 20px solid transparent;
      border-bottom: 20px solid #edf2ee;

      top: -4%;
      left: 60%;
    }
  }
  .info-tooltip {
    width: 60vw;
    height: max-content;
    position: absolute;
    transform: translateX(-50%);
    background-color: #ffa900;
    padding: 10px;
    border-radius: 4px;
    display: none;
    z-index: 1;
    margin-top: 0.5rem;
  }

  .info-field:hover .info-tooltip {
    display: block;
  }

  .info-content {
    color: black;
    .exemplo {
      color: #375a3a;
      font-weight: bold;
    }
  }

  div.info-content h2 {
    color: #375a3a;
    border-bottom: #375a3a solid 0.15rem;
    margin-bottom: 1rem;
  }

  .info-content p {
    margin: 0;
    padding: 0;
    margin-bottom: 5px;
  }
}

@media screen and (min-width: 426px) and (max-width: 1024px) {
  .modal-1 {
    left: -0.5rem;
    top: 5.5vh;

    &::after {
      content: "";

      width: 0;
      height: 0;

      position: absolute;

      border-left: 20px solid transparent;
      border-right: 20px solid transparent;
      border-bottom: 20px solid #edf2ee;

      top: -4%;
      left: 0%;
    }
  }
  .modal-2 {
    left: -2.5rem;
    top: 5.5vh;

    &::after {
      content: "";

      width: 0;
      height: 0;

      position: absolute;

      border-left: 20px solid transparent;
      border-right: 20px solid transparent;
      border-bottom: 20px solid #edf2ee;

      top: -4%;
      left: 13%;
    }
  }
  .modal-3 {
    left: -5.5rem;
    top: 5.5vh;

    &::after {
      content: "";

      width: 0;
      height: 0;

      position: absolute;

      border-left: 20px solid transparent;
      border-right: 20px solid transparent;
      border-bottom: 20px solid #edf2ee;

      top: -4%;
      left: 31%;
    }
  }
  .modal-4 {
    left: -9rem;
    top: 5.5vh;

    &::after {
      content: "";

      width: 0;
      height: 0;

      position: absolute;

      border-left: 20px solid transparent;
      border-right: 20px solid transparent;
      border-bottom: 20px solid #edf2ee;

      top: -4%;
      left: 60%;
    }
  }
  .info-tooltip {
    width: 60vw;
    height: max-content;
    position: absolute;
    transform: translateX(-50%);
    background-color: #ffa900;
    padding: 10px;
    border-radius: 4px;
    display: none;
    z-index: 1;
    margin-top: 0.5rem;
    left: 20vw;
  }

  .info-field:hover .info-tooltip {
    display: block;
  }

  .info-content {
    color: black;
    .exemplo {
      color: #375a3a;
      font-weight: bold;
    }
  }

  div.info-content h2 {
    color: #375a3a;
    border-bottom: #375a3a solid 0.15rem;
    margin-bottom: 1rem;
  }

  .info-content p {
    margin: 0;
    padding: 0;
    margin-bottom: 5px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .modal {
    width: 22rem !important;
  }
  .modal-1 {
    left: -0.5rem;
    top: 5.5vh;

    &::after {
      content: "";

      width: 0;
      height: 0;

      position: absolute;

      border-left: 20px solid transparent;
      border-right: 20px solid transparent;
      border-bottom: 20px solid #edf2ee;

      top: -4%;
      left: 0%;
    }
  }
  .modal-2 {
    left: -2.5rem;
    top: 5.5vh;

    &::after {
      content: "";

      width: 0;
      height: 0;

      position: absolute;

      border-left: 20px solid transparent;
      border-right: 20px solid transparent;
      border-bottom: 20px solid #edf2ee;

      top: -4%;
      left: 11%;
    }
  }
  .modal-3 {
    left: -5.5rem;
    top: 5.5vh;

    &::after {
      content: "";

      width: 0;
      height: 0;

      position: absolute;

      border-left: 20px solid transparent;
      border-right: 20px solid transparent;
      border-bottom: 20px solid #edf2ee;

      top: -4%;
      left: 25%;
    }
  }
  .modal-4 {
    left: -9rem;
    top: 5.5vh;

    &::after {
      content: "";

      width: 0;
      height: 0;

      position: absolute;

      border-left: 20px solid transparent;
      border-right: 20px solid transparent;
      border-bottom: 20px solid #edf2ee;

      top: -4%;
      left: 50%;
    }
  }
  .info-tooltip {
    width: max-content;
    height: max-content;
    position: absolute;
    transform: translateX(-50%);
    background-color: #ffa900;
    padding: 1rem;
    border-radius: 4px;
    display: none;
    z-index: 1;
    margin-top: 0.5rem;
    left: 25vw;
  }

  .info-field:hover .info-tooltip {
    display: block;
  }

  .info-content {
    color: black;
    .exemplo {
      color: #375a3a;
      font-weight: bold;
    }
  }

  div.info-content h2 {
    color: #375a3a;
    border-bottom: #375a3a solid 0.15rem;
    margin-bottom: 1rem;
  }

  .info-content p {
    margin: 0;
    padding: 0;
    margin-bottom: 5px;
  }
}

@media screen and (min-width: 1441px) and (max-width: 2560px) {
  .modal {
    width: 22rem !important;
  }
  .modal-1 {
    left: -0.5rem;
    top: 5vh;

    &::after {
      content: "";

      width: 0;
      height: 0;

      position: absolute;

      border-left: 20px solid transparent;
      border-right: 20px solid transparent;
      border-bottom: 20px solid #edf2ee;

      top: -4%;
      left: 0%;
    }
  }
  .modal-2 {
    left: -2.5rem;
    top: 5vh;

    &::after {
      content: "";

      width: 0;
      height: 0;

      position: absolute;

      border-left: 20px solid transparent;
      border-right: 20px solid transparent;
      border-bottom: 20px solid #edf2ee;

      top: -4%;
      left: 11%;
    }
  }
  .modal-3 {
    left: -5.5rem;
    top: 5vh;

    &::after {
      content: "";

      width: 0;
      height: 0;

      position: absolute;

      border-left: 20px solid transparent;
      border-right: 20px solid transparent;
      border-bottom: 20px solid #edf2ee;

      top: -4%;
      left: 25%;
    }
  }
  .modal-4 {
    left: -9rem;
    top: 5vh;

    &::after {
      content: "";

      width: 0;
      height: 0;

      position: absolute;

      border-left: 20px solid transparent;
      border-right: 20px solid transparent;
      border-bottom: 20px solid #edf2ee;

      top: -4%;
      left: 50%;
    }
  }
  .info-tooltip {
    width: max-content;
    height: max-content;
    position: absolute;
    transform: translateX(-50%);
    background-color: #ffa900;
    padding: 1rem;
    border-radius: 4px;
    display: none;
    z-index: 1;
    margin-top: 0.5rem;
    left: 15vw;
  }

  .info-field:hover .info-tooltip {
    display: block;
  }

  .info-content {
    color: black;
    .exemplo {
      color: #375a3a;
      font-weight: bold;
    }
  }

  div.info-content h2 {
    color: #375a3a;
    border-bottom: #375a3a solid 0.15rem;
    margin-bottom: 1rem;
  }

  .info-content p {
    margin: 0;
    padding: 0;
    margin-bottom: 5px;
  }
}
.slide-enter-active {
  -moz-transition-duration: 0.3s;
  -webkit-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -moz-transition-timing-function: ease-in;
  -webkit-transition-timing-function: ease-in;
  -o-transition-timing-function: ease-in;
  transition-timing-function: ease-in;
}

.slide-leave-active {
  -moz-transition-duration: 0.8s;
  -webkit-transition-duration: 0.8s;
  -o-transition-duration: 0.8s;
  transition-duration: 0.8s;
  -moz-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  -webkit-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  -o-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}

.slide-enter-to,
.slide-leave {
  max-height: 100px;
  overflow: hidden;
}

.slide-enter,
.slide-leave-to {
  overflow: hidden;
  max-height: 0;
}

.submit-busca-simples {
  cursor: pointer;
}

h1 {
  font-family: "Poppins-Bold";
  color: #2c3f2b;
  padding-left: 0.5rem;
  border-left: #ffa900 solid 0.15rem;
  margin: 2.5rem 0rem 1.5rem 1rem;
  font-size: 1.5rem;
  line-height: 2rem;
}
.pesquise {
  background-color: #375a3a;
  padding: 0.5rem 1rem 1.5rem;
  label {
    color: white;
    font-size: 0.9rem;
  }
  input {
    border: none;
    width: calc(100% - 0.8rem);
    height: 2rem;
    border-radius: 0.2rem;
    border: none;
    padding-left: 0.5rem;
    font-family: "Poppins";
    font-size: 1rem;
    letter-spacing: 0.03rem;
    color: black;
  }
  form {
    .campo-preenchido {
      background-color: #fffed4;
      // border: solid .15rem #ffa900;
      // box-shadow: 0rem 0rem 0rem 0.13rem #ffa900;
      // -moz-box-shadow: 0rem 0rem 0rem 0.13rem #ffa900;
      // -webkit-box-shadow: 0rem 0rem 0rem 0.13rem #ffa900;
    }
  }

  .busca-simples {
    width: calc(100%);
    background-color: white;
    position: relative;
    border-radius: 0.3rem;
    input {
      width: calc(100% - 3rem) !important;
    }
    button {
      height: 100%;
      width: 2.3rem;
      background-color: #2c3f2b;
      border: none;
      margin-left: auto;
      position: absolute;
      top: 0rem;
      right: 0rem;
      border-radius: 0rem 0.2rem 0.2rem 0rem;
      img {
        height: 1.3rem;
      }
    }
  }
  .botao {
    button {
      background-color: transparent;
      border: none;
      font-size: 0.9rem;
      font-family: "Poppins";
      color: #ffa900;
      padding: 0rem;
      letter-spacing: 0.03rem;
      span {
        font-size: 0.9rem;
      }
    }
    button:focus {
      box-shadow: none;
      outline: none;
      cursor: pointer;
    }
  }
  .botoes {
    float: right;
    margin: 1rem 0.2rem 1rem auto;
    button {
      margin-left: 1rem;
      padding: 0.1rem 2rem;
      font-family: "Poppins-Medium";
      font-size: 0.9rem;
    }
    button:first-child {
      background: transparent;
      border: white solid 0.15rem;
      color: white;
      padding: 0.1rem 2rem;
      cursor: pointer;
    }
    button:last-child {
      background-color: #ffa900;
      color: #2c3f2b;
      border: solid 0.15rem #ffa900;
      cursor: pointer;
    }
  }

  select {
    width: calc(100% - 0.2rem);
    height: 2rem;
    border: none;
    border-radius: 0.2rem;
    padding-left: 0.5rem;
    background-color: white;
    font-family: "Poppins";
    font-size: 1rem;
    letter-spacing: 0.04rem;
  }

  h2 {
    color: white;
    font-family: "Poppins-ExtraLight";
    font-size: 1.1rem;
    display: inline-table;
    border-bottom: #ffa900 solid 0.15rem;
    margin: 0rem;
    padding-right: 1rem;
  }
  .row2 {
    display: grid;
    .row2mobile {
      display: grid;
      grid-template-columns: auto auto;
      gap: 1rem;
    }
  }
  .grupo {
    border: #ffa900 solid 0.15rem;
    margin: 0.5rem 0rem;
    padding: 0.5rem 0.5rem;
    border-radius: 0.5rem;
    .data {
      display: grid;
      grid-template-columns: auto auto;
      gap: 1rem;
      .mx-datepicker {
        width: 100%;
      }
    }
    p {
      margin: 0rem;
      padding: 0rem;
      color: white;
    }
  }
  .item {
    display: grid;
    margin: 0.5rem 0rem;
    align-content: center;
  }
  .seta {
    img {
      width: 5rem;
      position: absolute;
      right: 1.5rem;
      margin-top: 0rem;
    }
    .t-preto {
      display: none;
    }
  }
}
.campo-resultados {
  margin: 1rem;
  h2 {
    color: #2c3f2b;
    font-family: "Poppins-ExtraLight";
    font-size: 1.1rem;
    border-bottom: #ffa900 0.15rem solid;
    margin: 1.5rem 0rem 0rem 1rem;
    display: table;
    padding-right: 1rem;
  }
  .dados-abertos {
    padding-left: 0.5rem;
    width: 15rem;
    margin: 1rem 1rem 0rem 0rem;
    p,
    span {
      margin: 0rem;
      font-size: 0.9rem;
      font-family: "Poppins-ExtraLight";
      a {
        margin: 0rem 0.35rem 0rem 1rem;
        text-decoration: none;
        color: #2c3f2b;
        font-family: "Poppins-Medium";
        cursor: pointer;
      }
    }
    p {
      background-color: #edf2ee;
      padding-left: 0.5rem;
      height: 1.3rem;
    }
  }
  .nav-resultados {
    margin-bottom: 2rem;
  }
  .nresultados {
    margin: 0rem auto 2rem;
    p {
      font-size: 0.9rem;
      margin: 0rem 0rem -2rem 1rem;
      strong {
        font-family: "Poppins-Medium";
      }
    }
  }
  .npagina {
    margin: 0.5rem 1rem 0.5rem 1rem;
    label {
      font-family: "Poppins-Medium";
      font-weight: 900;
      margin-right: 0.5rem;
      color: #2c3f2b;
      font-size: 0.9rem;
    }
    select {
      border: none;
      background-color: #edf2ee;
      font-size: 0.9rem;
      margin-top: 0.5rem;
    }
  }
}

@media screen and (min-width: 31rem) {
  .nav-resultados {
    margin-bottom: 0rem !important;
  }
}
@media screen and (min-width: 64rem) {
  h1 {
    margin-left: 0rem;
    font-size: 1.8rem;
  }
  h2 {
    font-size: 1.3rem !important;
  }

  .pesquise {
    padding-bottom: 2rem !important;

    h2 {
      margin-top: 1rem;
      margin-bottom: 1rem;
    }
    .label-busca {
      text-align: left;
      margin-bottom: 0.3rem;
    }
    .row2 {
      grid-template-columns: calc(50% - 1%) calc(50% - 1%);
      gap: 2%;
      .item {
        input {
          padding-right: 0.25rem;
        }
      }
    }
    .grupo {
      padding: 0.5rem 2rem;
    }
    .botoes {
      margin-right: 0rem;
      margin-top: 0.5rem;
      button {
        font-size: 1rem;
        padding: 0.1rem 4rem !important;
        border-radius: 0.2rem;
      }
    }
    .seta {
      img {
        right: 4.7rem;
        margin-top: 0.5rem;
      }
    }
  }
  .campo-resultados {
    h2 {
      margin-left: 0rem;
    }
    .dados-abertos {
      padding-left: 0.5rem;
      width: 13rem;
      margin: -2rem 1rem 0rem auto;
      text-align: right;
      p,
      span {
        font-size: 0.8rem;
      }
    }
    .npagina {
      text-align: right;
      label {
        font-size: 0.8rem;
      }
    }
    .nresultados {
      max-width: 17rem;
      text-align: left;
      margin: 0rem 0rem 2rem;
      p {
        font-size: 0.8rem;
        margin: -1.5rem 0rem 0rem 0rem;
        text-align: left;
        strong {
          font-family: "Poppins-Medium";
        }
      }
    }
  }
}
.alto-contraste {
  .pesquise {
    background-color: #213b27;
    .seta {
      .t-branco {
        display: none;
      }
      .t-preto {
        display: block;
      }
    }

    .botoes {
      button:last-child {
        background-color: #ffa900;
        color: #0d2b10;
        font-family: "Poppins-Medium";
      }
    }
  }
  .nav-resultados {
    h2 {
      color: white;
    }
  }
  .npagina {
    label {
      color: white !important;
    }
  }
  .nresultados {
    color: white;
  }
}
</style>
