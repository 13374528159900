<template>
  <form @submit.prevent="fazerBuscaSimples">
    <div class="busca-simples">
      <input
        type="text"
        :placeholder="`Digite o número ou texto ${contexto}`"
        id="inputPesquisa"
        :value="value"
        @input="updateValue($event.target.value)"
      />
      <button title="Pesquisar" type="submit" class="submit-busca-simples">
        <img src="../assets/images/pesquisa.png" class="pesquisa" alt=" " />
      </button>
    </div>
  </form>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      required: false,
      default: "",
    },
    contexto: {
      type: String,
      required: true,
    },
  },
  methods: {
    updateValue(newValue) {
      this.$emit("input", newValue);
    },
    fazerBuscaSimples() {
      this.$emit("busca-simples", this.value);
    },
  },
};
</script>

<style lang="scss" scoped>
.submit-busca-simples {
  cursor: pointer;
}

.pesquise {
  background-color: #375a3a;
  padding: 0.5rem 1rem 1.5rem;

  label {
    color: white;
    font-size: 0.9rem;
  }

  input {
    border: none;
    width: calc(100% - 0.8rem);
    height: 2rem;
    border-radius: 0.2rem;
    border: none;
    padding-left: 0.5rem;
    font-family: "Poppins";
    font-size: 1rem;
    letter-spacing: 0.03rem;
    color: black;
  }

  form {
    .campo-preenchido {
      background-color: #fffed4;
    }
  }

  .busca-simples {
    width: calc(100%);
    background-color: white;
    position: relative;
    border-radius: 0.3rem;
    input {
      width: calc(100% - 3rem) !important;
    }
    button {
      height: 100%;
      width: 2.3rem;
      background-color: #2c3f2b;
      border: none;
      margin-left: auto;
      position: absolute;
      top: 0rem;
      right: 0rem;
      border-radius: 0rem 0.2rem 0.2rem 0rem;
      img {
        height: 1.3rem;
      }
    }
  }
  .botao {
    button {
      background-color: transparent;
      border: none;
      font-size: 0.9rem;
      font-family: "Poppins";
      color: #ffa900;
      padding: 0rem;
      letter-spacing: 0.03rem;
      span {
        font-size: 0.9rem;
      }
    }
    button:focus {
      box-shadow: none;
      outline: none;
      cursor: pointer;
    }
  }
  .botoes {
    float: right;
    margin: 1rem 0.2rem 1rem auto;
    button {
      margin-left: 1rem;
      padding: 0.1rem 2rem;
      font-family: "Poppins-Medium";
      font-size: 0.9rem;
    }
    button:first-child {
      background: transparent;
      border: white solid 0.15rem;
      color: white;
      padding: 0.1rem 2rem;
      cursor: pointer;
    }
    button:last-child {
      background-color: #ffa900;
      color: #2c3f2b;
      border: solid 0.15rem #ffa900;
      cursor: pointer;
    }
  }

  select {
    width: calc(100% - 0.2rem);
    height: 2rem;
    border: none;
    border-radius: 0.2rem;
    padding-left: 0.5rem;
    background-color: white;
    font-family: "Poppins";
    font-size: 1rem;
    letter-spacing: 0.04rem;
  }

  h2 {
    color: white;
    font-family: "Poppins-ExtraLight";
    font-size: 1.1rem;
    display: inline-table;
    border-bottom: #ffa900 solid 0.15rem;
    margin: 0rem;
    padding-right: 1rem;
    width: fit-content;
  }
  .row2 {
    display: grid;
    .row2mobile {
      display: grid;
      grid-template-columns: auto auto;
      gap: 1rem;
    }
  }
  .grupo {
    border: #ffa900 solid 0.15rem;
    margin: 0.5rem 0rem;
    padding: 0.5rem 0.5rem;
    border-radius: 0.5rem;
    .data {
      display: grid;
      grid-template-columns: auto auto;
      gap: 1rem;
      .mx-datepicker {
        width: 100%;
      }
    }
    p {
      margin: 0rem;
      padding: 0rem;
      color: white;
    }
  }
  .item {
    display: grid;
    margin: 0.5rem 0rem;
    height: fit-content;
  }
  .seta {
    img {
      width: 5rem;
      position: absolute;
      right: 1.5rem;
      margin-top: 0rem;
    }
    .t-preto {
      display: none;
    }
  }
}

@media screen and (min-width: 64rem) {
  .pesquise {
    padding-bottom: 2rem !important;

    h2 {
      margin-top: 1rem;
      margin-bottom: 1rem;
    }

    .label-busca {
      text-align: left;
      margin-bottom: 0.3rem;
    }

    .row2 {
      grid-template-columns: calc(50% - 1%) calc(50% - 1%);
      gap: 2%;
      .item {
        input {
          padding-right: 0.25rem;
        }
        .gridbox-1 {
          display: grid;
        }
      }
    }

    .grupo {
      padding: 0.5rem 2rem;
    }

    .botoes {
      margin-right: 0rem;
      margin-top: 0.5rem;
      button {
        font-size: 1rem;
        padding: 0.1rem 4rem !important;
        border-radius: 0.2rem;
      }
    }

    .seta {
      img {
        right: 4.7rem;
        margin-top: 0.5rem;
      }
    }
  }
}
</style>
