<template>
  <div class="text-center my-3 tooltip">
    <div class="container-tooltip" @click="toggleModal()">
      <label>Clique aqui para entender a contagem de legislações</label>
      <div id="modal" class="modal" v-show="modalVisivel">
        <div class="arrow"></div>
        <div class="grid">
          <p v-if="texto.titulo" class="titulo">
            <strong v-if="!isHtmlContent(texto.titulo)">{{
              texto.titulo
            }}</strong>
            <span v-else v-html="texto.titulo"></span>
          </p>

          <p v-if="texto.descricao" class="descricao">
            <span
              v-if="isHtmlContent(texto.descricao)"
              v-html="texto.descricao"
            ></span>
            <span v-else>{{ texto.descricao }}</span>
          </p>

          <p v-if="texto.descricaoExemplo" class="descricao-exemplo">
            <strong>Exemplo: </strong>
            <span
              v-if="isHtmlContent(texto.descricaoExemplo)"
              v-html="texto.descricaoExemplo"
            ></span>
            <span v-else>{{ texto.descricaoExemplo }}</span>
          </p>

          <p v-if="texto.textoExemplo" class="texto-exemplo">
            <span
              v-if="isHtmlContent(texto.textoExemplo)"
              v-html="texto.textoExemplo"
            ></span>
            <strong v-else>{{ texto.textoExemplo }}</strong>
          </p>

          <p v-if="texto.complemento" class="complemento">
            <strong>Nota: </strong>
            <span
              v-if="isHtmlContent(texto.complemento)"
              v-html="texto.complemento"
            ></span>
            <span v-else>{{ texto.complemento }}</span>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ModalAjuda",
  props: {
    texto: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      modalVisivel: false,
    };
  },
  methods: {
    toggleModal() {
      this.modalVisivel = !this.modalVisivel;
    },
  },
  computed: {
    isHtmlContent() {
      return (content) => {
        const regex = /<\/?[a-z][\s\S]*>/i;
        return regex.test(content);
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.tooltip {
  display: flex;
  width: max-content;
  margin: 1rem 0 1rem 0;
}

img {
  max-width: 1.5rem;
  width: 7vw;
  height: auto;
  cursor: pointer;
}

img:hover {
  content: url("../assets/images/question.svg");
}

.container-tooltip {
  position: relative;
  display: flex;
  align-items: center;
  gap: 0.5rem;

  label {
    color: #e09600;
    font-weight: bold;
  }
}

@media screen and (min-width: 1441px) {
  .modal {
    width: 42rem;
    min-width: none;
    max-width: none;
    top: 2.3rem;

    .grid {
      display: grid;
      gap: 1rem;
      padding: 0.7rem 0.5rem;
      word-break: break-word;

      p {
        padding: 0;
        margin: 0;
      }

      .titulo {
        font-size: 1.2rem;
        justify-self: center;
      }
    }
  }

  .modal::before {
    left: 0.8%;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .modal {
    width: 42rem;
    min-width: none;
    max-width: none;
    top: 2.3rem;

    .grid {
      display: grid;
      gap: 1rem;
      padding: 0.7rem 0.5rem;
      word-break: break-word;

      p {
        padding: 0;
        margin: 0;
      }

      .titulo {
        font-size: 1.2rem;
        justify-self: center;
      }
    }
  }

  .modal::before {
    left: 0.8%;
  }
}

@media screen and (min-width: 769px) and (max-width: 1024px) {
  .modal {
    width: 45rem;
    min-width: none;
    max-width: none;
    left: 0.5rem;
    top: 2.3rem;

    .grid {
      display: grid;
      gap: 1rem;
      padding: 0.7rem 0.5rem;
      word-break: break-word;

      p {
        padding: 0;
        margin: 0;

        span {
          ul {
            margin: 0;
          }
        }
      }

      .titulo {
        font-size: 1.2rem;
        justify-self: center;
      }
    }
  }

  .modal::before {
    left: 0.9%;
  }
}

@media screen and (min-width: 635px) and (max-width: 768px) {
  .modal {
    width: 35rem;
    min-width: none;
    max-width: none;
    left: 1rem;
    top: 2.3rem;

    .grid {
      display: grid;
      gap: 1rem;
      padding: 0.7rem 0.5rem;
      word-break: break-word;

      p {
        padding: 0;
        margin: 0;
        font-size: 0.9rem;
      }

      .titulo {
        font-size: 1rem;
        justify-self: center;
      }
    }
  }

  .modal::before {
    left: 1.2%;
  }
}

@media screen and (min-width: 320px) and (max-width: 634px) {
  .modal {
    width: 90vw;
    min-width: none;
    max-width: none;
    bottom: 1.8rem;

    .grid {
      display: grid;
      gap: 0.5rem;
      padding: 0.3rem 0.3rem;
      word-break: break-word;

      p {
        padding: 0;
        margin: 0;
        font-size: 0.7rem;
      }

      .titulo {
        font-size: 0.8rem;
        justify-self: center;
      }
    }
  }

  .modal::before {
    left: 1.2%;
  }
}

.modal {
  z-index: 1000;
  position: absolute;
  font-size: 0.9rem;
  background-color: #edf2ee;
  box-shadow: 3px 5px 5px #ffa900;
  padding: 0.3rem 1rem;
  border-radius: 0.5rem;

  .grid {
    p {
      padding: 0;
      margin: 0;

      span {
        ul {
          margin: 0;
        }
      }
    }
  }
}
</style>
