import axios from "axios";
import axiosInstance from "./axiosInstance";

class CategoriaLegislacaoApi {
  cancelTokens = {
    getCategoriasLegislacao: null,
  };

  isCancel(exceptionThrown) {
    return axios.isCancel(exceptionThrown);
  }

  async getCategoriasLegislacao() {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    this.cancelTokens.getCategoriasLegislacao = source;

    const { data } = await axiosInstance.get(
      `${process.env.VUE_APP_BASE_API_URL}/api/v2/pesquisa/legislacoes/categorias`,
      {
        cancelToken: source.token,
      }
    );

    this.cancelTokens.getCategoriasLegislacao = null;
    return data;
  }
}

export default CategoriaLegislacaoApi;
