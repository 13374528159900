import BuscaSimplesCards from '@/components/BuscaSimplesCards.vue';

export const mixinMensagemVeto = {
  components: {
    BuscaSimplesCards,
  },
  data() {
    return {
        cardsMensagemVeto: [
            {
              rota: 'PorTipoLegislacaoMensagemVeto',
              icone: require('@/assets/images/icon-tipo-legislacao.png'),
              iconeClasse: 'legisla-icon',
              titulo: 'Tipo de Legislação',
              descricao: 'Consulte a Mensagem de Veto por tipo de legislação',
            },
            {
              rota: 'PorTipoVetoMensagemVeto',
              icone: require('@/assets/images/icon-tipo-veto.png'),
              iconeClasse: 'legisla-icon',
              titulo: 'Tipo de Veto',
              descricao: 'Consulte a Mensagem de Veto por tipo de veto',
            },
            {
              rota: 'PorAnoMensagemVeto',
              icone: require('@/assets/images/icon-indice-cronologico.png'),
              iconeClasse: 'indice-icon',
              titulo: 'Índice Cronológico',
              descricao: 'Consulte a Mensagem de Veto por ano',
            },
          ],
    };
  },
};