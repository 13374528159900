import BuscaSimplesCards from '@/components/BuscaSimplesCards.vue';

export const mixinAtoInfralegal = {
  components: {
    BuscaSimplesCards,
  },
  data() {
    return {
      cardsAtoInfralegal: [
        {
          rota: 'PorTipoAtoInfralegal',
          icone: require('@/assets/images/icon-tipo-legislacao.png'),
          iconeClasse: 'legisla-icon',
          titulo: 'Tipo de Ato Infralegal',
          descricao: 'Consulte o Ato Infralegal por tipo',
        },
        {
          rota: 'PorOrgaoAtoInfralegal',
          icone: require('@/assets/images/orgao-atos-icon-min.png'),
          iconeClasse: 'orgao-icon',
          titulo: 'Órgão',
          descricao: 'Consulte o Ato Infralegal por órgão',
        },
        {
          rota: 'PorAnoAtoInfralegal',
          icone: require('@/assets/images/icon-indice-cronologico.png'),
          iconeClasse: 'indice-icon',
          titulo: 'Índice Cronológico',
          descricao: 'Consulte o Ato Infralegal por ano',
        },
      ],
    };
  },
};