<template>
  <div class="orgao">
    <div class="centro">
      <h1>Consulta por Órgãos</h1>
      <BuscaSimplesCards
        titulo="Consultas Rápidas"
        :cards="cardsAtoInfralegal"
      />
      <Loading v-if="carregando" />
      <ErroResultados v-if="!carregando && erro" />
      <PainelBuscaSimplificada
        :dados="orgaosFormatados"
        :generateUrl="generateUrl"
        titulo="Órgãos relacionados"
        v-if="!carregando && !erro"
      />
    </div>
  </div>
</template>

<script>
import AtoInfralegalApi from "../../services/AtoInfralegalApi";
import Loading from "../../components/Loading";
import ErroResultados from "../../components/ErroResultados";
import PainelBuscaSimplificada from "../../components/PainelBuscaSimplificada";
import { mixinAtoInfralegal } from "../../mixins/mixinAtoInfralegal";

import metaDados from "../../helpers/metaDados";

export default {
  name: "PorOrgaoAtoInfralegal",
  metaInfo() {
    return {
      title: "Pesquisa de Ato Infralegal por Órgãos",
      titleTemplate: "%s - Casa Civil do Estado de Goiás",
      meta: [
        {
          vmid: "description",
          name: "description",
          property: "description",
          content:
            "Página de acesso e pesquisa a toda legislação e atos normativos do Governo do Estado de Goiás.",
        },
        {
          vmid: "og:description",
          property: "og:description",
          content:
            "Página de acesso e pesquisa a toda legislação e atos normativos do Governo do Estado de Goiás.",
        },
        {
          vmid: "og:title",
          property: "og:title",
          content:
            "Pesquisa o Ato Infralegal por Órgão - Casa Civil do Estado de Goiás",
        },
        {
          vmid: "og:type",
          property: "og:type",
          content: "article",
        },
        {
          vmid: "twitter:description",
          name: "twitter:description",
          property: "twitter:description",
          content:
            "Página de acesso e pesquisa a toda legislação e atos normativos do Governo do Estado de Goiás.",
        },
        {
          vmid: "twitter:title",
          name: "twitter:title",
          property: "twitter:title",
          content:
            "Pesquisa o Ato Infralegal por Órgão - Casa Civil do Estado de Goiás",
        },
        ...metaDados(this.$route),
      ],
    };
  },
  mixins: [mixinAtoInfralegal],
  data() {
    return {
      carregando: true,
      erro: false,
      orgaos: [],
      filtro: {
        orgao_emissor: [],
        ano: null,
      },
    };
  },
  computed: {
    // Parte para formatar os números com separador milhar.
    orgaosFormatados() {
      return this.orgaos.map((orgao) => {
        return {
          ...orgao,
          quantidade: orgao.quantidade_total,
        };
      });
    },
  },
  components: {
    PainelBuscaSimplificada,
    Loading,
    ErroResultados,
  },
  created() {
    this.atoInfralegalApi = new AtoInfralegalApi();

    this.carregarOrgaos();
  },
  methods: {
    carregarOrgaos: async function () {
      try {
        if (this.atoInfralegalApi.cancelTokens.getOrgaos) {
          this.atoInfralegalApi.cancelTokens.getOrgaos.cancel();
        }

        this.carregando = true;
        this.erro = false;
        this.orgaos = await this.atoInfralegalApi.getOrgaos();
        this.carregando = false;
      } catch (err) {
        if (this.atoInfralegalApi.isCancel(err)) {
          return;
        }
        console.log(err);
        this.erro = true;
        this.carregando = false;
      }
    },
    generateUrl(ano, dados) {
      this.filtro.orgao_emissor.push(dados.id);
      this.filtro.ano = ano;

      this.$router.push({
        name: "PesquisaAtoInfralegal",
        query: {
          ...this.filtro,
          pagina: 1,
          qtd_por_pagina: this.qtd_por_pagina,
          busca_avancada: 1,
        },
      });
    },
    formatNumber(number) {
      // Método para formatar os números com separador milhar.
      if (number) return number.toLocaleString("pt-BR");
    },
  },
};
</script>

<style lang="scss" scoped>
h1 {
  font-family: "Poppins-Bold";
  color: #2c3f2b;
  padding-left: 0.5rem;
  border-left: #ffa900 solid 0.15rem;
  margin: 2rem 0rem 1.5rem 0.5rem;
  font-size: 1.5rem;
}
.orgao {
  min-height: calc(100vh - 10rem);
  margin-bottom: 5rem;
}
</style>
