var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"resultados"},[_c('table',[_vm._m(0),_c('tbody',[_vm._l((_vm.mensagensVeto),function(mensagem){return _c('tr',{key:mensagem.id},[_c('td',{staticClass:"topo-card"},[_vm._v(_vm._s(_vm.tituloMensagemVeto(mensagem)))]),_c('td',{attrs:{"data-label":"Tipo de legislação:"}},[_vm._v(" "+_vm._s(mensagem.tipo_legislacao ? mensagem.tipo_legislacao.nome : "")+" ")]),_c('td',{attrs:{"data-label":"Data:"}},[_vm._v(_vm._s(_vm.dataMensagem(mensagem)))]),_c('td',{attrs:{"data-label":"Tipo de Veto:"}},[_vm._v(" "+_vm._s(_vm.capitalize(mensagem.tipo_veto))+" "),(mensagem.tipo_veto === 'Parcial' && mensagem.legislacao)?[_c('br'),_c('router-link',{attrs:{"to":{
                name: 'PesquisaLegislacaoShow',
                params: { id: mensagem.legislacao.id },
              },"target":"_blank"}},[_vm._v(_vm._s(mensagem.tipo_legislacao ? mensagem.tipo_legislacao.nome : "")+" "+_vm._s(mensagem.legislacao.numero))])]:_vm._e()],2),_c('td',{attrs:{"data-label":"Texto:"}},[_c('router-link',{attrs:{"to":{
              name: 'PesquisaMensagemVetoShow',
              params: { id: mensagem.id },
            },"target":"_blank"}},[_c('img',{staticClass:"icon-txt t-preto",attrs:{"src":require("../../assets/images/icon_text.svg"),"alt":"Acessar Texto","title":"Acessar texto"}}),_c('img',{staticClass:"icon-txt t-branco",attrs:{"src":require("../../assets/images/icon_text_bco.svg"),"alt":"Acessar Texto","title":"Acessar texto"}})]),_c('a',{attrs:{"href":_vm.urlPdf(mensagem),"target":"_blank"},on:{"click":function($event){return _vm.registra_GA4(mensagem)}}},[_c('img',{staticClass:"icon-pdf",attrs:{"src":require("../../assets/images/icon_pdf.svg"),"alt":"Abrir texto em pdf","title":"Abrir texto em PDF"}})])],1),_c('td',{attrs:{"data-label":"Ementa:"}},[_vm._v(_vm._s(mensagem.ementa))])])}),(!_vm.mensagensVeto || _vm.mensagensVeto.length == 0)?_c('tr',{staticClass:"sem-resultados"},[_c('td',{attrs:{"colspan":"6"}},[_vm._v("Nenhum resultado encontrado")])]):_vm._e()],2)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',[_c('th',[_vm._v("Número")]),_c('th',[_vm._v("Tipo de legislação")]),_c('th',[_vm._v("Data")]),_c('th',[_vm._v("Tipo de Veto")]),_c('th',[_vm._v("Texto")]),_c('th',[_vm._v("Ementa")])])])
}]

export { render, staticRenderFns }