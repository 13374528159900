<template>
  <div id="app">
    <BannerDeAmbiente :ambienteAtual="ambienteAtual" />
    <Menu v-if="!exibirApenasConteudo"></Menu>
    <!-- <keep-alive :include="['PesquisaLegislacao', 'PesquisaMensagemVeto', 'PesquisaIndexAtoAdministrativo']"> -->
    <router-view></router-view>
    <!-- </keep-alive> -->
    <Footer v-if="!exibirApenasConteudo"></Footer>
  </div>
</template>

<script>
import Vue from "vue";
import VueRouter from "vue-router";
import VueMeta from "vue-meta";
import VueScrollTo from "vue-scrollto";
import VueSocialSharing from "vue-social-sharing";

import router from "./router";
import Menu from "./components/Menu";
import BannerDeAmbiente from './components/BannerDeAmbiente';
import Footer from "./components/Footer";

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => {
    if (err && err.name !== "NavigationDuplicated") return err;
  });
};

Vue.use(VueRouter);
Vue.use(VueMeta);
Vue.use(VueScrollTo, {
  container: "body",
  duration: 500,
  easing: "ease",
  offset: 0,
  force: true,
  cancelable: true,
  onStart: false,
  onDone: false,
  onCancel: false,
  x: false,
  y: true,
});
Vue.use(VueSocialSharing);

export default {
  name: "App",
  router,
  data() {
    const rotasApenasConteudo = [
      "PesquisaLegislacaoShow",
      "PesquisaAtoInfralegalShow",
      "PesquisaMensagemVetoShow",
      "ConstituicaoEstadual",
    ];
    const ambienteAtual = process.env.VUE_APP_ENV;
    return {
      rotasApenasConteudo,
      exibirApenasConteudo: rotasApenasConteudo.includes(
        this.$router.currentRoute.name
      ),
      ambienteAtual,
    };
  },
  components: {
    Menu,
    Footer,
    BannerDeAmbiente,
  },
  watch: {
    $route: function (to) {
      this.exibirApenasConteudo = this.rotasApenasConteudo.includes(to.name);
    },
  },
};
</script>

<style lang="scss">
  .v-autocomplete {
    width: 100%;
    .v-autocomplete-input-group {
      .v-autocomplete-input {
        border: none;
        width: 100%;
        height: 2rem;
        border-radius: 0.2rem;
        border: none;
        padding-left: 0.5rem;
        font-family: "Poppins";
        font-size: 1rem;
        letter-spacing: 0.03rem;
        color: black;
      }
    }
    .v-autocomplete-list {
      z-index: 9999;
      width: 100%;
      text-align: left;
      border: none;
      border-top: none;
      max-height: 200px;
      overflow-y: auto;
      border-bottom: 1px solid #375a3a;
      .v-autocomplete-list-item {
        cursor: pointer;
        background-color: #fff;
        padding-left: 0;
        border-bottom: 1px solid #375a3a;
        border-left: 1px solid #375a3a;
        border-right: 1px solid #375a3a;
        &:last-child {
          border-bottom: none;
        }
        abbr {
          opacity: 0.8;
          font-size: 0.8em;
          display: block;
          font-family: sans-serif;
        }
      }
    }
  }  

</style>
