import BuscaSimplesCards from '@/components/BuscaSimplesCards.vue';

export const mixinProjetoGovernador = {
  components: {
    BuscaSimplesCards,
  },
  data() {
    return {
        cardsProjetoGovernador: [
            {
              rota: 'PorAnoProjetoGovernador',
              icone: require('@/assets/images/icon-indice-cronologico.png'),
              iconeClasse: 'indice-icon',
              titulo: 'Índice Cronológico',
              descricao: 'Consulte Projetos por ano',
            },
          ],
    };
  },
};