<template>
  <div class="painel">
    <h3 class="centro">{{ titulo }}</h3>
    <div
      v-for="dado in dados"
      :key="dado.id"
      class="item centro"
      :data-group-id="groupId(dado)"
      :id="groupId(dado)"
    >
      <details>
        <summary>
          <span>
            {{ dado.nome }}
            <i>({{ dado.quantidade_total }})</i>
          </span>
        </summary>
        <div class="esconder">
          <div class="botoes-anos">
            <button
              v-for="ano in dado.anos_total"
              :key="ano"
              @click="generateUrl(ano, dado)"
            >
              {{ ano }}
            </button>
            <button @click="generateUrl(null, dado)">TODOS OS ANOS</button>
          </div>
          <!-- Renderiza os filhos primeiro -->
          <div
            v-for="filho_n1 in dado.children"
            :key="filho_n1.id"
            class="item centro"
            :data-group-id="groupId(filho_n1)"
            :id="groupId(filho_n1)"
          >
            <details>
              <summary>
                <span>
                  {{ filho_n1.nome }}
                  <i>({{ filho_n1.quantidade_total }})</i>
                </span>
              </summary>
              <div class="esconder">
                <div class="botoes-anos">
                  <button
                    v-for="ano_filho_n1 in filho_n1.anos_total"
                    :key="ano_filho_n1"
                    @click="generateUrl(ano_filho_n1, filho_n1)"
                  >
                    {{ ano_filho_n1 }}
                  </button>
                  <button @click="generateUrl(null, filho_n1)">
                    TODOS OS ANOS
                  </button>
                </div>
                <!-- Renderiza os netos primeiro -->
                <div
                  v-for="filho_n2 in filho_n1.children"
                  :key="filho_n2.id"
                  class="item centro"
                  :data-group-id="groupId(filho_n2)"
                  :id="groupId(filho_n2)"
                >
                  <details>
                    <summary>
                      <span>
                        {{ filho_n2.nome }}
                        <i>({{ filho_n2.quantidade_total }})</i>
                      </span>
                    </summary>
                    <div class="esconder">
                      <div class="botoes-anos">
                        <button
                          v-for="ano_filho_n2 in filho_n2.anos_total"
                          :key="ano_filho_n2"
                          @click="generateUrl(ano_filho_n2, filho_n2)"
                        >
                          {{ ano_filho_n2 }}
                        </button>
                        <button @click="generateUrl(null, filho_n2)">
                          TODOS OS ANOS
                        </button>
                      </div>
                      <!-- Adiciona o card "outras" para netos, se houver -->
                      <div
                        v-if="
                          filho_n2.quantidade > 0 &&
                          filho_n2.quantidade_total > filho_n2.quantidade
                        "
                        class="item centro"
                      >
                        <details>
                          <summary>
                            <span>
                              Outras
                              <i>({{ filho_n2.quantidade }})</i>
                            </span>
                          </summary>
                          <div class="esconder">
                            <div class="botoes-anos">
                              <button
                                v-for="ano in filho_n2.anos"
                                :key="ano"
                                @click="generateUrlSolo(ano, filho_n2)"
                              >
                                {{ ano }}
                              </button>
                              <button @click="generateUrlSolo(null, filho_n2)">
                                TODOS OS ANOS
                              </button>
                            </div>
                          </div>
                        </details>
                      </div>
                    </div>
                  </details>
                </div>
                <!-- Adiciona o card "outras" para filhos, se houver -->
                <div
                  v-if="
                    filho_n1.quantidade > 0 &&
                    filho_n1.quantidade_total > filho_n1.quantidade
                  "
                  class="item centro"
                >
                  <details>
                    <summary>
                      <span>
                        Outras
                        <i>({{ filho_n1.quantidade }})</i>
                      </span>
                    </summary>
                    <div class="esconder">
                      <div class="botoes-anos">
                        <button
                          v-for="ano in filho_n1.anos"
                          :key="ano"
                          @click="generateUrlSolo(ano, filho_n1)"
                        >
                          {{ ano }}
                        </button>
                        <button @click="generateUrlSolo(null, filho_n1)">
                          TODOS OS ANOS
                        </button>
                      </div>
                    </div>
                  </details>
                </div>
              </div>
            </details>
          </div>
          <!-- Adiciona o card "outras" para a categoria pai, se houver -->
          <div
            v-if="
              dado.quantidade > 0 && dado.quantidade_total > dado.quantidade
            "
            class="item centro"
          >
            <details>
              <summary>
                <span>
                  Outras
                  <i>({{ dado.quantidade }})</i>
                </span>
              </summary>
              <div class="esconder">
                <div class="botoes-anos">
                  <button
                    v-for="ano in dado.anos"
                    :key="ano"
                    @click="generateUrlSolo(ano, dado)"
                  >
                    {{ ano }}
                  </button>
                  <button @click="generateUrlSolo(null, dado)">
                    TODOS OS ANOS
                  </button>
                </div>
              </div>
            </details>
          </div>
        </div>
      </details>
    </div>
    <div class="sem-resultados" v-if="dados.length <= 0">
      Nenhum resultado encontrado
    </div>
    <div class="sumario" v-if="exibirIndice">
      <template v-for="indice in barraIndice">
        <span
          v-if="desabilitado(indice)"
          :key="indice"
          class="link-desabilitado"
        >
          {{ indice }}
        </span>
        <a
          v-else
          :href="'#' + indice"
          :class="{ active: indice == itemAtivo }"
          :key="indice"
        >
          {{ indice }}
        </a>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: "PainelBuscaSimplificada",
  props: {
    titulo: {
      type: String,
      required: true,
    },
    dados: {
      type: Array,
      required: true,
    },
    exibirIndice: {
      type: Boolean,
    },
    idProIndice: {
      type: Function,
    },
    generateUrl: {
      type: Function,
    },
    generateUrlSolo: {
      type: Function,
    },
  },
  data() {
    return {
      itemAtivo: "A",
      habilitados: new Set(),
      barraIndice: [
        "A",
        "B",
        "C",
        "D",
        "E",
        "F",
        "G",
        "H",
        "I",
        "J",
        "K",
        "L",
        "M",
        "N",
        "O",
        "P",
        "Q",
        "R",
        "S",
        "T",
        "U",
        "V",
        "W",
        "X",
        "Y",
        "Z",
      ],
    };
  },
  methods: {
    groupId(dado) {
      const nome = this.idProIndice ? this.idProIndice(dado) : dado.nome;
      if (nome) {
        const indice = nome[0].toUpperCase();
        return indice;
      }
      return "";
    },
    desabilitado(valor) {
      return !this.habilitados.has(valor);
    },
  },
};
</script>

<style lang="scss" scoped>
.sumario {
  display: grid;
  position: fixed;
  z-index: 100;
  right: 0.5rem;
  top: 50%;
  background-color: rgba(90, 90, 90, 0.3);
  padding: 0rem 0rem;
  text-align: center;
  border-radius: 1rem;
  transform: translateY(-50%);
  width: 1.5rem;
  a {
    text-decoration: none;
    color: black;
    cursor: pointer;
    font-size: 1.75vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .active {
    background-color: #375a3a;
    height: 1.5rem;
    color: #ffa900;
    border-radius: 0.75rem;
  }
  .link-desabilitado {
    cursor: not-allowed;
    font-size: 1.75vh;
    color: gray;
  }
}

.painel {
  margin-bottom: 3rem;
  position: relative;
}

h3 {
  font-size: 1rem;
  color: white;
  padding: 0.2rem 0.5rem;
  background-color: #368d49;
  margin: 0;
  position: sticky;
  top: 0;
  z-index: 10;
}

.item {
  width: -webkit-fill-available;
  padding: 0.8rem 1.5rem;

  text-align-last: left;
  position: relative;
  transition: background-color 0.2s;

  > details {
    > summary {
      list-style: none;
      position: relative;
      padding-right: 2rem;
      cursor: pointer;

      &::after {
        content: "+";
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        font-size: 1.5rem;
        line-height: 1;
        transition: transform 0.2s;
      }

      span {
        font-family: "Poppins-Medium";
        display: inline-block;
        max-width: calc(100% - 2rem);
      }
    }

    &[open] > summary::after {
      transform: translateY(-50%) rotate(45deg);
    }
  }

  .item {
    margin-left: 1.5rem;
    padding-left: 1rem;
    border-left: 2px solid #e0e0e0;
    background-color: #fcfcfc;

    &:nth-child(odd) {
      background-color: #f7f7f7;
    }

    > details > summary::after {
      right: 0;
      font-size: 1.5rem;
    }
  }

  .esconder {
    padding: 0.5rem 0 0 0.5rem;

    .botoes-anos {
      display: flex;
      flex-wrap: wrap;
      gap: 0.5rem;
      margin-bottom: 1rem;
      margin-top: 0.5rem;

      a {
        margin: 0;
        padding: 0.3rem 0.8rem;
        border: 1px solid #375a3a;
        border-radius: 1rem;
        font-size: 0.85rem;
        transition: all 0.2s;
        text-decoration: none;
        color: #375a3a;
        background: white;

        &:hover {
          background-color: #375a3a;
          color: white;
          transform: translateY(-1px);
        }
      }

      button {
        margin: 0;
        padding: 0.3rem 0.8rem;
        border: 1px solid #375a3a;
        border-radius: 1rem;
        font-size: 0.85rem;
        transition: all 0.2s;
        text-decoration: none;
        color: #375a3a;
        background: white;

        &:hover {
          background-color: #375a3a;
          color: white;
          transform: translateY(-1px);
        }
      }
    }
  }
}

.sem-resultados {
  background-color: transparent !important;
  text-align: center;
  height: 5rem;
  color: rgb(73, 73, 73);
  font-style: oblique;
}

/* Estilos para Alto Contraste */
.alto-contraste {
  h3 {
    background-color: #19381c;
  }

  .item {
    &:nth-child(odd) {
      background-color: #2a2a2a;
    }

    .item {
      border-left-color: #444;
      background-color: #333;

      &:nth-child(odd) {
        background-color: #2a2a2a;
      }
    }

    details summary::after {
      color: #ffa900;
    }

    .esconder .botoes-anos a {
      border-color: #ffa900;
      color: #ffa900;
      background-color: #1a1a1a;

      &:hover {
        background-color: #ffa900;
        color: #1a1a1a;
      }
    }
  }
}

/* Animações e detalhes */
details[open] summary ~ * {
  animation: open 0.3s ease-in-out;
}

@keyframes open {
  0% {
    opacity: 0;
    transform: translateY(-10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
</style>
