<template>
  <div>
    <BotaoEditar
      v-if="usuarioEstaLogado()"
      :url="`https://admin.legisla.casacivil.go.gov.br/legislacoes/${legislacao.id}/edit`"
    ></BotaoEditar>
    <div v-if="legislacao.layout_antigo" class="layout-antigo">
      <div v-html="legislacao.conteudo"></div>
      <FichaTecnica :legislacao="legislacao" :leisrelacionadasOrdenadas="leisrelacionadasOrdenadas"></FichaTecnica>
    </div>
    <div class="pagina-exibir" id="exibir_legislacao_layout_novo" v-else>
      <div class="folha">
        <template v-if="todosRelacionamentos.length > 0">
          <input id="atv-menu" type="checkbox" />
          <label for="atv-menu" id="atv-menu-ato">
            <div class="menu">
              <span class="hamburger"></span>
              <p>Atos Relacionados</p>
            </div>
          </label>
          <div class="veto" id="veto">
            <div v-for="(coluna, index) in colunasAtosRelacionados" :key="'coluna'+index">
              <p
                v-for="relacionamento in coluna" 
                :key="relacionamento.id"
              >
              
                <router-link v-if="relacionamento.tipo_ato == 'mensagem_veto'"
                  :to="{
                    name: 'PesquisaMensagemVetoShow',
                    params: { id: relacionamento.id },
                  }"
                  target="_blank"
                >
                &#9655; Mensagem de veto {{ relacionamento.numero }} /
                  {{ relacionamento.ano }}
                </router-link>
                <router-link v-else
                  :to="{
                    name: 'PesquisaLegislacaoShow',
                    params: { id: relacionamento.id },
                  }"
                  target="_blank"
                >
                  &#9655; {{ titleLegislacao(relacionamento) }}
                </router-link>
              </p>
            </div>
          </div>
        </template>
        <header>
          <img src="../../assets/images/brasao-de-goias.svg" class="Brasao" />
          <h1>Estado de Goiás</h1>
        </header>

        <template v-if="possuiTextoRevogadoNotaVide && legislacao.estado_legislacao_id != 3"> <!-- ID 3 corresponde à legislações revogadas -->
          <input
            type="checkbox"
            id="texto-compilado"
            class="texto-compilado"
            v-model="textoCompilado"
          />
          <label
            for="texto-compilado"
            class="texto-compilado-label"
            id="text-compilado-label"
          >
            &nbsp;
            <span>Texto Compilado</span>
          </label>
        </template>
        <div v-html="legislacao.conteudo"></div>

        <div
          class="dados-diario"
          v-if="legislacao.diarios_estaduais.length > 0"
        >
          <span>
            Este texto não substitui o publicado
            <template v-for="(diario, index) in legislacao.diarios_estaduais">
              <a :href="diario.url" :key="diario.id.toString() + 'comUrl'" v-if="diario.url">
                {{ labelDiario(diario) }}
              </a>
              <span v-else :key="diario.id.toString() + 'semUrl'">{{ labelDiario(diario) }}</span>
              <span
                :key="diario.id.toString() + 'virgula'"
                v-if="index < legislacao.diarios_estaduais.length - 2"
                >,</span
              >
              <span
                :key="diario.id.toString() + 'virgula2'"
                v-if="index == legislacao.diarios_estaduais.length - 2"
                >e</span
              >
            </template>
          </span>
        </div>
        <FichaTecnica :legislacao="legislacao" :leisrelacionadasOrdenadas="leisrelacionadasOrdenadas"></FichaTecnica>
      </div>

      <div class="botoes" id="botoes">
        <div class="botao">
          <router-link :to="{ name: 'PesquisaLegislacao' }" target="blank">
            <img
              src="../../assets/images/lupa-bco.svg"
              class="lupa"
              title="Pesquisar Nova Legislação"
            />
            Pesquisar Legislação
          </router-link>
        </div>
        <share-network
          network="whatsapp"
          :url="url"
          :title="titleLegislacao(legislacao)"
          :description="legislacao.ementa"
          class="icon"
        >
          <img
            src="../../assets/images/icon-whatsapp.png"
            class="icone-whatsapp"
            title="Compartilhar no Whatsapp"
          />
        </share-network>
        <share-network
          network="facebook"
          :url="url"
          :title="titleLegislacao(legislacao)"
          :description="legislacao.ementa"
          hashtags="legisla,governo-de-goias"
          class="icon"
        >
          <img
            src="../../assets/images/icon-facebook.png"
            class="icone-facebook"
            title="Compartilhar no Facebook"
          />
        </share-network>
        <share-network
          network="twitter"
          :url="url"
          :title="titleLegislacao(legislacao)"
          hashtags="legisla,governo-de-goias"
          class="icon"
        >
          <img
            src="../../assets/images/icon-twitter.png"
            class="icone-twitter"
            title="Compartilhar no X"
          />
        </share-network>
      </div>
    </div>
  </div>
</template>

<script>
import { ShareNetwork } from "vue-social-sharing";
import { parseISO, format } from "date-fns";

import titleLegislacao from "../../helpers/legislacao/titleLegislacao";
import usuarioEstaLogado from "../../helpers/usuarioEstaLogado";
import BotaoEditar from "../BotaoEditar";
import FichaTecnica from "./FichaTecnica";

export default {
  name: "ExibirLei",
  components: {
    ShareNetwork,
    BotaoEditar,
    FichaTecnica,
  },
  props: {
    legislacao: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      textoCompilado: false,
      url: window.location.href,
      possuiTextoRevogadoNotaVide:
        this.legislacao.conteudo.match(/class\s*=\s*(['"])(?:(?!\1).)*\brevogado\b(?:(?!\1).)*\1/) ||
        this.legislacao.conteudo.includes("<s>") ||
        this.legislacao.conteudo.includes("<strike>") ||
        this.legislacao.conteudo.includes("<nota>") ||
        this.legislacao.conteudo.includes("<vide>"),
    };
  },
  watch: {
    textoCompilado: function (novoValor) {
      const elementosByClass =
        document.getElementsByClassName("conteudo-revogado");
      for (let element of elementosByClass) {
        element.style.display = novoValor ? "none" : "";
      }
      const elementosByTagS = document.getElementsByTagName("s");
      for (let element of elementosByTagS) {
        element.style.display = novoValor ? "none" : "";
      }
      const elementosByTagStrike = document.getElementsByTagName("strike");
      for (let element of elementosByTagStrike) {
        element.style.display = novoValor ? "none" : "";
      }
      const elementosByTagNota = document.getElementsByTagName("nota");
      for (let element of elementosByTagNota) {
        const prevElement = element.previousElementSibling;
        if (prevElement && prevElement.tagName.toLowerCase() === "br") {
          prevElement.style.display = novoValor ? "none" : "";
        }
        element.style.display = novoValor ? "none" : "";
      }
      const elementosByTagVide = document.getElementsByTagName("vide");
      for (let element of elementosByTagVide) {
        const prevElement = element.previousElementSibling;
        if (prevElement && prevElement.tagName.toLowerCase() === "br") {
          prevElement.style.display = novoValor ? "none" : "";
        }
        element.style.display = novoValor ? "none" : "";
      }
    },
  },
  methods: {
    titleLegislacao,
    labelDiario: function (diario) {
      let msg = "";
      if (diario.errata && diario.suplemento) {
        msg = " na Errata no Suplemento do ";
      } else if (diario.errata) {
        msg = " na Errata do ";
      } else if (diario.suplemento) {
        msg = " no Suplemento do ";
      }
      return `${msg || " no"} D.O de ${this.formatarData(diario.data)} `;
    },
    formatarData: function (data) {
      return format(parseISO(data), "dd/MM/yyyy");
    },
    descricaoLeiExtensa: function () {
      return `${this.legislacao.tipo_legislacao.nome} ${
        this.legislacao.numero ? this.legislacao.numero : ""
      },  ${this.formatarData(this.legislacao.data_legislacao)}`;
    },
    usuarioEstaLogado,
    alteraTargetLinks: function () {
      var links = document.getElementsByTagName("a");
      for (var i = 0, l = links.length; i < l; i++) {
        links[i].target = "_blank";
      }    
    }
  },
  mounted(){
    this.alteraTargetLinks();
  },
  computed: {
    leisrelacionadasOrdenadas() {
      let atosRelacionados = [...this.legislacao.legislacoes_relacionadas]

      // Ordem de apresentação dos atos relacionados
      const ordemLista = [12, 13, 1, 2, 4, 8, 3, 5, 7, 6, 11];
      
      return atosRelacionados.sort((a, b) => {
        const tipoA = ordemLista.indexOf(a.tipo_legislacao.id);
        const tipoB = ordemLista.indexOf(b.tipo_legislacao.id);

        // Se os tipos de legislação forem iguais, compara por numero
        if (tipoA === tipoB) {
          // Converte os valores de numero para inteiros antes de comparar
          const numeroB = parseInt(b.numero?.replace('.', ''), 10);
          const numeroA = parseInt(a.numero?.replace('.', ''), 10);
          return numeroA - numeroB;
        }

        // Caso contrário, compara por índice na ordemLista
        return tipoA - tipoB;
      })
    },
    todosRelacionamentos() {
      let vetos = this.legislacao.mensagens_veto.map(obj => ({...obj, tipo_ato: 'mensagem_veto'}));
      let leis = this.leisrelacionadasOrdenadas.map(obj => ({...obj, tipo_ato: 'legislacao'}));
      let todasMescladas = [...vetos, ...leis];
      return todasMescladas;
    },
    // Divide as leis relacionadas em 3 colunas, sendo que começa a mostrar a segunda coluna apenas quando completar 5 leis na primeira.
    colunasAtosRelacionados() {
      let colunasAtosRelacionados = [];
      let itensPorColuna;
      if (this.todosRelacionamentos.length <= 15) {
        itensPorColuna = 5;
      }
      else {
        itensPorColuna = Math.ceil(this.todosRelacionamentos.length / 3)
      }

      let nrColuna = 0;
      colunasAtosRelacionados[nrColuna] = [];

      for (let i = 0; i < this.todosRelacionamentos.length; i++) {
        colunasAtosRelacionados[nrColuna].push(this.todosRelacionamentos[i]);

        // Se é o último elemento da primeira ou da segunda coluna, e não é a última lei relacionada,
        // então cria a próxima coluna de leis.
        if ((i == itensPorColuna -1 || i == itensPorColuna * 2 - 1) && i != this.todosRelacionamentos.length - 1) {
          nrColuna += 1;
          colunasAtosRelacionados[nrColuna] = [];
        }
      }
      return colunasAtosRelacionados;
    }
  }
};
</script>

<style lang="scss">
.input:checked ~ .menu-label .hamburger {
  background: red;
}

.layout-antigo{
  font-family: "Calibri";
  margin-bottom: 60px;

  .ficha-tecnica{
    margin-left: 50px;

    table {
      min-width: 600px;
    }
  }
}

.pagina-exibir {
  font-family: "Calibri";
  background-color: silver;
  min-height: 100vh;
  overflow-x: hidden;
  p {
    text-align: justify;
    line-height: 1.5rem;
    text-indent: 5.9rem;
  }

  .folha {
    padding: 5rem 0.5rem;
    background-color: white;
    max-width: 50rem;
    margin: auto;
    min-height: 100vh;
    overflow-x: scroll;
    position: relative;
    .dados-diario {
      text-align: center;
      color: red;
      margin-top: 1.5rem;
      margin-bottom: 1rem;

      a {
        color: red;
      }
    }

    .menu {
      width: auto;
      height: 2rem;
      position: absolute;
      top: 3rem;
      z-index: 3;

      .hamburger {
        display: block;
        background-color: #49a054;
        width: 1.4rem;
        height: 2px;
        position: relative;
        z-index: 12;
        top: 1rem;
        left: 0.3rem;
      }

      .hamburger::before,
      .hamburger::after {
        background-color: #49a054;
        content: "";
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        transition: 0.5s ease-in-out;
      }

      .hamburger::before {
        top: 0.4em;
      }

      .hamburger::after {
        bottom: 0.35em;
      }

      p {
        text-transform: uppercase;
        text-indent: 0rem;
        margin: 0.3rem 0rem 0rem 2.4rem;
        color: #49a054;
      }
    }

    input {
      display: none;
    }

    input:checked ~ label {
      .hamburger {
        transform: rotate(45deg);
      }

      .hamburger::before,
      .hamburger::after {
        transform: rotate(90deg);
        top: 0;
      }
    }

    input:checked ~ .veto {
      width: 100%;
    }

    .veto {
      position: absolute;
      float: left;
      background-color: #49a054;
      margin-left: -0.5rem;
      height: auto;
      transition: width 0.3s ease-in-out;
      z-index: 2;
      width: 0rem;
      white-space: nowrap;
      overflow: hidden;
      p {
        color: white;
        text-indent: 0rem;
        margin-left: 1rem;
      }

      a {
        color: white;
        margin-bottom: 0.5rem;
        font-size: 0.9rem;
        text-decoration: none;
        padding: 1rem;
        margin-left: 1rem;
      }
    }

    header {
      text-transform: uppercase;
      text-align: center;

      img {
        width: 5.23rem !important;
        padding-left: 0rem !important;
      }

      h1 {
        font-size: 0.7rem !important;
        font-weight: bold;
      }
    }
    .sumario {
      border: solid 0.1rem #49a054;
      input:checked ~ label {
        span:first-child {
          display: none;
        }
        span:last-child {
          display: block;
        }
      }
      input:checked ~ .sumario-conteudo {
        height: 30rem;
        overflow-y: scroll;
      }
      label {
        width: calc(100% - 2rem);
        display: block;
        padding: 0.3rem 1rem;
        color: #49a054;
        font-size: 1.3rem;
        cursor: pointer;
        span {
          margin-left: auto;
          float: right;
        }
        span:last-child {
          display: none;
        }
      }
      .sumario-conteudo {
        height: 0rem;
        overflow: hidden;
        transition: 0.3s linear;
        p {
          text-indent: 0rem;
          margin-right: 0.5rem;
        }
        .margin1-sumario {
          margin-left: 1rem;
          font-weight: 800;
        }
        .margin2-sumario {
          margin-left: 2rem;
          font-weight: 600;
        }
        .margin3-sumario {
          margin-left: 3rem;
        }
        .margin4-sumario {
          margin-left: 4rem;
        }
      }
    }

    .epigrafe {
      margin-top: 1rem;
      text-transform: uppercase;
      font-size: 1rem;
      text-align: center;
      font-family: "Calibri-Bold";
      text-indent: 0rem;
    }

    .texto-compilado {
      visibility: hidden;
    }

    .texto-compilado-label {
      position: relative;
      cursor: pointer;
      text-transform: uppercase;
      color: #49a054;
    }

    .texto-compilado-label::before,
    .texto-compilado-label::after {
      content: "";
      transition: 0.1s ease-in-out;
    }

    .texto-compilado-label::before {
      display: inline-block;
      width: 2em;
      height: 1em;
      background-color: silver;
      border-radius: 1em;
      margin-top: -0.3rem;
    }

    .texto-compilado-label::after {
      position: absolute;
      top: -0.2em;
      left: 0.05rem;
      background-color: white;
      border-radius: 100%;
      width: 0.9em;
      height: 0.9em;
    }

    .texto-compilado:checked + .texto-compilado-label::before {
      background-color: #49a054;
    }

    .texto-compilado:checked + .texto-compilado-label::after {
      left: 1.05rem;
    }

    .texto-compilado:checked ~ .revogado {
      visibility: hidden;
    }

    .informacao-compilacao {
      display: block;
      margin-top: -0.3rem;
      margin-bottom: 0rem;
      text-indent: 0rem;
    }

    .ementa {
      height: auto;
      text-align: justify;
      width: 50%;
      margin-left: auto;
      text-indent: 0rem;
      margin-top: 1rem;
    }

    .preambulo {
      text-align: justify;
      margin-top: 2rem;
      margin-bottom: 0rem;

      b {
        text-transform: uppercase;
        font-family: "Calibri-Bold";
      }
    }

    .agrupamento {
      margin-top: 2.5rem;
      margin-bottom: 0rem;
      text-transform: uppercase;
      text-indent: 0rem !important;
      text-align: center;
    }

    .filho-agrupamento {
      margin-top: 0.5rem;
      margin-bottom: 2rem;
      text-transform: uppercase;
      text-indent: 0rem !important;
      text-align: center;
      // max-width: 35rem;
    }

    .sub-agrupamento {
      margin-top: 2.5rem;
      margin-bottom: 0rem;
      text-align: center;
      font-family: "Calibri-Bold";
      text-indent: 0rem !important;
    }

    .filho-sub-agrupamento {
      margin-top: 0.5rem;
      margin-bottom: 2rem;
      text-align: center;
      font-family: "Calibri-Bold";
      text-indent: 0rem !important;
      // max-width: 35rem;
    }

    .titulo-centralizado {
      margin: 2.5rem 0rem 0rem 0rem !important;
      margin-bottom: 0rem;
      text-align: center;
      text-indent: 0rem !important;
    }
    
    p[style*="text-align: center"] {
      text-indent: 0rem;
    }

    .tematica {
      text-indent: 0rem;
      font-family: "Calibri-Bold";
    }

    .artigo {
      text-align: justify;
      margin-bottom: 0.5rem;
    }

    .paragrafo {
      text-align: justify;
      margin-top: 0rem !important;
      margin-bottom: 0.5rem;
    }

    .paragrafo-decreta-resolve {
      text-align: justify;
      margin-bottom: 0.5rem;
      // text-indent: 0 !important;
    }

    .paragrafo-sem-margem {
      text-align: justify;
      margin-top: 0rem !important;
      margin-bottom: 0.5rem;
      text-indent: 0 !important;
    }

    .inciso {
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;
    }

    .alinea {
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;
    }

    .revogado {
      color: red;
    }

    s {
      color: red;
    }

    .conteudo-revogado {
      color: red;
      text-decoration: line-through;

      span {
        text-decoration: none !important;
      }
    }

    .nota,
    nota {
      font-size: 0.665em !important;
      font-family: "Arial Black";
      text-transform: none;
      color: black;
    }

    vide {
      font-size: 0.8312em !important;
      font-family: "Times New Roman", Times, serif;
      color: green;
      text-indent: 0rem;
      text-transform: none;
      // margin-left: 7.1rem;
      // margin-right: 3.5rem;
      a {
        color: green;
      }
    }

    .revogado-economia {
      color: red;
    }

    .itens {
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;
    }
    .citacao-com-recuo {
      margin-left: 5.9rem;
      text-indent: 3.5rem;
    }

    .citacao-sem-recuo {
      margin-left: 5.9rem;
      text-indent: 0rem;
      margin-top: -1rem;
    }

    .botao-baixar {
      background-image: linear-gradient(to top, #e6e6e6, #f0f0f0);
      width: 15rem;
      height: 4rem;
      margin: 0rem auto;
      border-radius: 2rem;
      position: relative;
      margin-bottom: 2rem;

      a {
        text-decoration: none;
      }

      p {
        text-indent: -3rem;
        color: black;
        margin: 0rem;
        text-align: center;
      }

      .baixar {
        text-transform: uppercase;
        font-size: 1.5rem;
        font-family: "Calibri-Bold";
        padding-top: 1rem;
      }

      .arquivo {
        color: #49a054;
        font-size: 0.5rem;
        margin-top: -0.5rem !important;
      }

      .verde {
        background-color: #49a054;
        z-index: 2;
        width: 3rem;
        height: 5rem;
        position: absolute;
        right: 1rem;
        top: -0.5rem;

        img {
          width: 2rem;
          margin: 1.4rem 0rem 0rem 0.5rem;
        }
      }

      .triangulo {
        width: 0;
        height: 0;
        border-left: 0.7rem solid transparent;
        border-right: 0.7rem solid transparent;
        border-bottom: 0.5rem solid #204d26;
        position: absolute;
        right: 4rem;
      }

      .triangulo:first-child {
        transform: translateY(-0.5rem) translateX(0.7rem);
      }

      .triangulo:last-child {
        transform: translateY(0.5rem) translateX(0.7rem) rotate(180deg);
      }
    }

    img {
      max-width: calc(100% - 10rem);
      display: block;
      margin: 0rem auto;
      padding-bottom: 2rem;
      padding-left: 4rem;
    }

    .baixar-anexo {
      text-align: center;
      margin-bottom: 1rem;

      a {
        width: 15rem;
        text-decoration: none;
        color: #49a054;
      }

      img {
        width: 15rem;
        position: relative;
      }

      p {
        font-size: 0.5rem;
        text-indent: 0rem;
        left: 50%;
        transform: translateX(-75%);
        position: absolute;
        margin-top: -3rem;
      }
    }

    table {
      //table-layout: fixed;
      border-collapse: collapse;
      margin-top: 2rem;

      .texto-centralizado {
        text-align: center;
      }

      p {
        text-indent: 0rem;
        margin: 0rem;
        text-align: left;
        line-height: 100%;
        font-size: 90%;
      }

      table,
      th,
      td {
        border: solid 0.03rem black;
        font-size: 85%;
        // font-size: clamp(13px, 85%, 16px);
      }

      th[colspan] {
        padding: 0.7rem 0rem;
      }

      th,
      td {
        padding: 0.25rem;
      }

      td[colspan] {
        p {
          text-align: center;
        }
      }

      td:first-child {
        text-transform: none !important;
      }

      th {
        background-color: #efefef;
      }
    }
  }

  .botoes {
    display: flex;
    position: absolute;
    top: 0;
    right: 1rem;
    margin: 1rem auto;
    align-items: center;

    .botao {
      a {
        background-color: #45b053;
        align-content: center;
        padding: 0.25rem 1rem;
        border-radius: 1rem;
        text-decoration: none;
        color: white;
        font-family: "Calibri-Bold";

        img {
          margin-bottom: -0.2rem;
          height: 1rem;
        }
      }
    }

    img {
      height: 1.5rem;
      transition: 0.3s ease-in-out;
      margin-left: 0.5rem;
      margin-top: -0.1rem;
    }
  }

  footer {
    .assinatura {
      .governador {
        text-transform: uppercase;
        margin-bottom: 0rem;
      }

      p {
        text-indent: 0rem !important;
        margin-top: 0rem;
        text-align: center;
        padding: 0;
        margin-bottom: 1rem;
      }
    }
  }
  .nota-rodape {
    font-size: 0.8rem;
    text-indent: 0rem;
    margin-left: 5.9rem;
  }
}

@media screen and (min-width: "50em") {
  .pagina-exibir {
    padding-top: 1rem;
    padding-bottom: 1rem;

    .folha {
      padding-top: 4.7rem;
      padding-bottom: 4.7rem;
      overflow-x: visible;
      p,
      ol {
        margin-left: 7.1em;
        margin-right: 3.5em;
      }
      input:checked ~ .veto {
        width: 51rem;
      }
      .dados-diario {
        margin-left: 7.1rem;
        margin-right: 3.5rem;
        span {
          max-width: 35rem;
          display: inline-block;
        }
      }

      .veto {
        display: grid;
        grid-template-columns: 32% 32% 32%;
        gap: 1%;
        height: auto;
        max-width: 51rem !important;

        a:hover {
          text-decoration: underline;
        }
      }

      header {
        img {
          height: 7rem;
        }

        h1 {
          margin-top: 0rem;
          font-size: 1rem;
        }
      }
      .sumario {
        width: 38.5em;
        margin-left: 7.1rem;
      }
      table {
        width: 80%;
        margin: 2rem auto;
        max-width: 48rem;
      }

      .ementa {
        width: 21.26rem;
      }
    }

    .citacao-com-recuo {
      margin-left: 13rem !important;
    }

    .citacao-sem-recuo {
      margin-left: 13rem !important;
      text-indent: 0rem;
    }

    .nota-rodape {
      font-size: 0.8rem;
      margin: 0rem 4rem 0.5rem 12rem !important;
    }

    .botoes {
      display: grid;
      margin-top: 1.5rem;

      .botao {
        a {
          background-color: #45b053;
          align-content: center;
          padding: 0.5rem 2rem 0.5rem 2rem;
          border-radius: 1rem;
          text-decoration: none;
          color: white;
          font-family: "Calibri-Bold";

          img {
            float: none;
            margin-bottom: -0.3rem;
            margin-right: 0.5rem;
            height: 1.2rem;
          }
        }
      }

      img {
        height: 2rem;
        margin-top: 1rem;
        float: right;
      }

      img:hover {
        height: 2.2rem;
      }
    }
  }
}

@media print {
  #atv-menu,
  #veto,
  #botoes,
  #editar-publico,
  #atv-menu-ato,
  #texto-compilado,
  #text-compilado-label {
    display: none;
  }
}
</style>
