<template>
  <div class="text-center my-3 tooltip">
    <div
      class="container-tooltip"
      v-for="(operador, index) in operadores"
      :key="index"
    >
      <button
        @click.prevent="adicionarOperador(operador.valor)"
        @mouseover="mostrarModal(index + 1)"
        @mouseleave="esconderModal(index + 1)"
        :id="'tooltip-target-' + (index + 1)"
        class="button"
      >
        {{ operador.label }}
      </button>
      <div
        :id="'modal' + (index + 1)"
        class="modal"
        :class="'modal-' + (index + 1)"
      >
        <p v-html="operador.descricao"></p>
        <p v-if="operador.exemplo">
          <span>Exemplo:</span> {{ operador.exemplo }}
        </p>
        <p v-if="operador.textoExemplo">
          <span>{{ operador.textoExemplo }}</span>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "OperadoresBusca",
  data() {
    return {
      operadores: [
        {
          label: "E",
          valor: "&",
          descricao:
            'Para buscar vários termos, insira-os separados por um espaço ou pelo caractere <span>"&".</span>',
          exemplo:
            'Se você deseja encontrar um ato pela frase "Conceder à empresa um crédito outorgado" e que contenha o termo "ICMS"',
          textoExemplo: '"Conceder à empresa um crédito outorgado" & ICMS',
        },
        {
          label: "OU",
          valor: "OR",
          descricao:
            'Para buscar um termo ou outro, insira os termos separados pela palavra <span>"OR".</span>',
          exemplo:
            'Se você deseja encontrar um ato pelos termos "IPVA" ou "ICMS"',
          textoExemplo: "ICMS OR IPVA",
        },
        {
          label: "NÃO",
          valor: "-",
          descricao:
            'Para excluir um termo da busca, insira o caractere <span>"-"</span> antes do termo.',
          exemplo:
            'Se você deseja encontrar um ato que não contenha o termo "ICMS"',
          textoExemplo: "-ICMS",
        },
        {
          label: "FRASE ÚNICA",
          valor: '" "',
          descricao:
            'Para buscar uma frase, insira a frase entre aspas <span>(" ").</span>',
          exemplo:
            'Se você deseja encontrar um ato pela frase "Conceder à empresa um crédito outorgado"',
          textoExemplo: '"Conceder à empresa um crédito outorgado"',
        },
      ],
    };
  },
  methods: {
    adicionarOperador(operador) {
      this.$emit("adicionar-operador", operador);
    },
    mostrarModal(numero) {
      const modalAtivo = document.getElementById("modal" + numero);
      if (modalAtivo.style.display === "none") {
        modalAtivo.style.display = "block";
      } else {
        modalAtivo.style.display = "none";
      }
    },
    esconderModal() {
      for (let i = 1; i <= this.operadores.length; i++) {
        const modal = document.getElementById("modal" + i);
        if (modal) {
          modal.style.display = "none";
        }
      }
    },
  },
};
</script>

<style scoped>
.tooltip {
  display: flex;
  width: max-content;
  margin: 1rem 0 1rem 0;
}

.button {
  margin: 0.3rem 0.7rem 0 0;
  font-size: 0.9rem;
  background-color: #edf2ee;
  border: none;
  border-radius: 0.3rem;
  padding: 0.3rem 0.5rem;
  box-shadow: 1px 2px 1px #ffa900;
  cursor: pointer;
}

.button:hover {
  box-shadow: 0px 0px 0px;
  padding: 0.27rem 0.47rem;
  font-size: 0.87rem;
  background-color: #ffa900;
}

.container-tooltip {
  position: relative;
}

.modal {
  display: block;
  width: 16rem;
  z-index: 1000;
  position: absolute;
  max-width: 20rem;
  font-size: 0.9rem;
  background-color: #edf2ee;
  box-shadow: 3px 5px 5px #ffa900;
  padding: 0.3rem 1rem;
  border-radius: 0.5rem;
  display: none;
}

.modal span {
  font-weight: bold;
}

/* Posicionamento dos modais para diferentes tamanhos de tela */
@media screen and (min-width: 320px) and (max-width: 425px) {
  .modal-1 {
    left: -0.5rem;
    top: 5.5vh;
  }
  .modal-2 {
    left: -2.5rem;
    top: 5.5vh;
  }
  .modal-3 {
    left: -5.5rem;
    top: 5.5vh;
  }
  .modal-4 {
    left: -9rem;
    top: 5.5vh;
  }
}

@media screen and (min-width: 426px) and (max-width: 1024px) {
  .modal-1 {
    left: -0.5rem;
    top: 5.5vh;
  }
  .modal-2 {
    left: -2.5rem;
    top: 5.5vh;
  }
  .modal-3 {
    left: -5.5rem;
    top: 5.5vh;
  }
  .modal-4 {
    left: -9rem;
    top: 5.5vh;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .modal {
    width: 22rem !important;
  }
  .modal-1 {
    left: -0.5rem;
    top: 5vh;
  }
  .modal-2 {
    left: -2.5rem;
    top: 5vh;
  }
  .modal-3 {
    left: -5.5rem;
    top: 5vh;
  }
  .modal-4 {
    left: -9rem;
    top: 5vh;
  }
}

@media screen and (min-width: 1441px) and (max-width: 2560px) {
  .modal {
    width: 22rem !important;
  }
  .modal-1 {
    left: -0.5rem;
    top: 5vh;
  }
  .modal-2 {
    left: -2.5rem;
    top: 5vh;
  }
  .modal-3 {
    left: -5.5rem;
    top: 5vh;
  }
  .modal-4 {
    left: -9rem;
    top: 5vh;
  }
}

/* Estilo para alto contraste */
.alto-contraste .button {
  background-color: #213b27;
  color: white;
}

.alto-contraste .modal {
  background-color: #0d2b10;
  color: white;
  border: 1px solid #ffa900;
}
</style>
