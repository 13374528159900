import axios from "axios";
import axiosInstance from "./axiosInstance";
import queryString from 'query-string';

class LegislacaoApi {
  cancelTokens = {
    getLegislacoes: null,
    getLegislacao: null,
    getMaisRecentes: null,
    getConstituicaoEstadual: null,
    getAnos: null,
    getCategorias: null,
    getTipos: null,
    getAutores: null
  };

  isCancel(exceptionThrown) {
    return axios.isCancel(exceptionThrown);
  }

  async getLegislacoes({
      page = 1,
      numero,
      conteudo,
      qtd_por_pagina,
      tipo_legislacao,
      categoria_legislacao,
      ementa,
      autor,
      ano,
      periodo_inicial_legislacao,
      periodo_final_legislacao,
      periodo_inicial_diario,
      periodo_final_diario,
      termo,
      ordenarPor,
      solo
  }) {
      // Definir valores padrão para os parâmetros que podem ser nulos
      const queryParams = {
        page,
        qtd_por_pagina,
        numero: numero ?? null,
        conteudo: conteudo ?? null,
        tipo_legislacao: tipo_legislacao?.length ? tipo_legislacao : null, // Evita array vazio na query
        categoria_legislacao: categoria_legislacao ?? null,
        ementa: ementa ?? null,
        autor: autor ?? null,
        ano: ano ?? null,
        periodo_inicial_legislacao: periodo_inicial_legislacao ?? null,
        periodo_final_legislacao: periodo_final_legislacao ?? null,
        periodo_inicial_diario: periodo_inicial_diario ?? null,
        periodo_final_diario: periodo_final_diario ?? null,
        termo: termo ?? null,
        ordenarPor: ordenarPor ?? 'data',
        solo: solo ?? null,
      };

      // Construir a query string garantindo que arrays sejam unidos por vírgula
      const query = queryString.stringify(queryParams, { skipNull: true, arrayFormat: 'comma' });

      const CancelToken = axios.CancelToken;
      const source = CancelToken.source();

      this.cancelTokens.getLegislacoes = source;

      const { data } = await axiosInstance.get(
          `${process.env.VUE_APP_BASE_API_URL}/api/v2/pesquisa/legislacoes?${query}`,
          {
              cancelToken: source.token,
          }
      );

      this.cancelTokens.getLegislacoes = null;

      return data;
  }

  async getLegislacao(legislacaoId) {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    this.cancelTokens.getLegislacao = source;

    const { data } = await axiosInstance.get(
      `${process.env.VUE_APP_BASE_API_URL}/api/v2/pesquisa/legislacoes/${legislacaoId}`,
      {
        cancelToken: source.token,
      }
    );

    this.cancelTokens.getLegislacao = null;

    return data;
  }

  async getConstituicaoEstadual() {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    this.cancelTokens.getConstituicaoEstadual = source;

    const { data } = await axiosInstance.get(
      `${process.env.VUE_APP_BASE_API_URL}/api/v2/pesquisa/legislacoes/constituicao_estadual`,
      {
        cancelToken: source.token,
      }
    );

    this.cancelTokens.getConstituicaoEstadual = null;
    return data;
  }

  async getMaisRecentes() {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    this.cancelTokens.getMaisRecentes = source;

    const { data } = await axiosInstance.get(
      `${process.env.VUE_APP_BASE_API_URL}/api/v2/pesquisa/legislacoes/mais_recentes`,
      {
        cancelToken: source.token,
      }
    );

    this.cancelTokens.getMaisRecentes = null;

    return data;
  }

  async getAnos() {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    this.cancelTokens.getAnos = source;

    const { data } = await axiosInstance.get(
      `${process.env.VUE_APP_BASE_API_URL}/api/v2/pesquisa/legislacoes/anos`,
      {
        cancelToken: source.token,
      }
    );

    this.cancelTokens.getAnos = null;

    return data;
  }

  async getCategorias() {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    this.cancelTokens.getCategorias = source;

    const { data } = await axiosInstance.get(
      `${process.env.VUE_APP_BASE_API_URL}/api/v2/pesquisa/legislacoes/categorias`,
      {
        cancelToken: source.token,
      }
    );

    this.cancelTokens.getCategorias = null;

    return data;
  }

  async getTipos() {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    this.cancelTokens.getTipos = source;

    const { data } = await axiosInstance.get(
      `${process.env.VUE_APP_BASE_API_URL}/api/v2/pesquisa/legislacoes/tipos`,
      {
        cancelToken: source.token,
      }
    );

    this.cancelTokens.getTipos = null;

    return data;
  }

  async getAutores() {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    this.cancelTokens.getAutores = source;

    const { data } = await axiosInstance.get(
      `${process.env.VUE_APP_BASE_API_URL}/api/v2/pesquisa/legislacoes/autores`,
      {
        cancelToken: source.token,
      }
    );

    this.cancelTokens.getAutores = null;

    return data;
  }
}

export default LegislacaoApi;