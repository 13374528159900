<template>
  <div class="resultados">
    <table>
      <thead>
        <tr>
          <th>Número</th>
          <th>Tipo de legislação</th>
          <th>Data</th>
          <th>Tipo de Veto</th>
          <th>Texto</th>
          <th>Ementa</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="mensagem in mensagensVeto" :key="mensagem.id">
          <td class="topo-card">{{ tituloMensagemVeto(mensagem) }}</td>
          <td data-label="Tipo de legislação:">
            {{ mensagem.tipo_legislacao ? mensagem.tipo_legislacao.nome : "" }}
          </td>
          <td data-label="Data:">{{ dataMensagem(mensagem) }}</td>
          <td data-label="Tipo de Veto:">
            {{ capitalize(mensagem.tipo_veto) }}
            <template
              v-if="mensagem.tipo_veto === 'Parcial' && mensagem.legislacao"
            >
              <br />
              <router-link
                :to="{
                  name: 'PesquisaLegislacaoShow',
                  params: { id: mensagem.legislacao.id },
                }"
                target="_blank"
                >{{
                  mensagem.tipo_legislacao ? mensagem.tipo_legislacao.nome : ""
                }}
                {{ mensagem.legislacao.numero }}</router-link
              >
            </template>
          </td>
          <td data-label="Texto:">
            <router-link
              :to="{
                name: 'PesquisaMensagemVetoShow',
                params: { id: mensagem.id },
              }"
              target="_blank"
            >
              <img
                src="../../assets/images/icon_text.svg"
                class="icon-txt t-preto"
                alt="Acessar Texto"
                title="Acessar texto"
              />
              <img
                src="../../assets/images/icon_text_bco.svg"
                class="icon-txt t-branco"
                alt="Acessar Texto"
                title="Acessar texto"
              />
            </router-link>
            <a
              @click="registra_GA4(mensagem)"
              :href="urlPdf(mensagem)"
              target="_blank"
            >
              <img
                src="../../assets/images/icon_pdf.svg"
                class="icon-pdf"
                alt="Abrir texto em pdf"
                title="Abrir texto em PDF"
              />
            </a>
          </td>
          <td data-label="Ementa:">{{ mensagem.ementa }}</td>
        </tr>
        <tr
          v-if="!mensagensVeto || mensagensVeto.length == 0"
          class="sem-resultados"
        >
          <td colspan="6">Nenhum resultado encontrado</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { format, parseISO } from "date-fns";
import registraEventoGoogleAnalytics from "../../helpers/registraEventoGoogleAnalytics";

export default {
  name: "TabelaResultadosMensagemVeto",
  props: {
    mensagensVeto: {
      type: Array,
      required: true,
    },
  },
  methods: {
    tituloMensagemVeto: function (mensagem) {
      return `Ofício Nº ${mensagem.numero}/${mensagem.ano}`;
    },
    dataMensagem: function (mensagem) {
      return format(parseISO(mensagem.data), "dd/MM/yyyy");
    },
    urlPdf: function (mensagemVeto) {
      return `${process.env.VUE_APP_BASE_API_URL}/api/v1/pesquisa/mensagens_veto/${mensagemVeto.id}/pdf`;
    },
    capitalize(s) {
      if (typeof s !== "string") return "";

      if (!s || s == "") return "";

      return s.charAt(0).toUpperCase() + s.slice(1);
    },
    registra_GA4(mensagemVeto) {
      registraEventoGoogleAnalytics(
        this.$gtag,
        "visualizacao_pdf_mensagemveto",
        "download_pdf_mensagemveto",
        "PDF - " + this.tituloMensagemVeto(mensagemVeto)
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.resultados {
  table {
    border-collapse: collapse;
    width: 100%;
    thead {
      border-bottom: solid #ffa900 0.15rem;
      color: #0a4a22;
      th {
        margin: 0rem 2rem;
      }
      th:first-child {
        text-align: left;
        width: 14%;
      }
      th:nth-child(2) {
        text-align: center;
        width: 14%;
      }
      th:nth-child(3) {
        text-align: center;
        width: 10%;
      }
      th:nth-child(4) {
        text-align: center;
        width: 17%;
      }
      th:nth-child(5) {
        text-align: center;
        width: 10%;
      }
    }
    tbody {
      tr {
        margin: 1rem;
        td {
          padding: 0.5rem;
          a {
            color: #375a3a;
          }
        }
        td:nth-child(1) {
          text-align: left;
        }
        td:nth-child(2) {
          text-align: center;
        }
        td:nth-child(3) {
          text-align: center;
        }
        td:nth-child(4) {
          text-align: center;
        }
        td:nth-child(5) {
          text-align: center;
        }
        td:nth-child(6) {
          text-align: justify;
        }
      }
      tr:nth-child(even) {
        background-color: #edf2ee;
      }
      .sem-resultados {
        background-color: transparent !important;
        text-align: center !important;
        height: 5rem;
        color: rgb(73, 73, 73);
        font-style: oblique;
        td {
          text-align: center !important;
        }
      }
    }
    .botao {
      width: 7rem;
      padding-bottom: 1rem;
      text-align: center;
      margin-top: 1rem;
      button {
        background-color: transparent;
        font-family: "Poppins";
        color: #0a4a22;
        border: #0a4a22 solid 0.15rem;
        border-radius: 0.2rem;
        padding: 0rem 1rem;
        font-size: 1rem;
        cursor: pointer;
      }
    }
  }
  td {
    img {
      height: 1.5rem;
      margin: 0rem 0.5rem 0rem 0.5rem;
    }
    .t-branco {
      display: none;
    }
  }
}
.alto-contraste {
  .resultados {
    table {
      thead {
        border-bottom: solid #ffa900 0.15rem;
        color: #ffa900;
      }
      tbody {
        color: white;
        tr:nth-child(even) {
          background-color: #202020;
        }
        .sem-resultados {
          color: white;
        }
      }
    }
    td {
      .t-preto {
        display: none;
      }
      .t-branco {
        display: inline;
      }
    }
  }
}
@media screen and (max-width: 64rem) {
  .resultados {
    table {
      width: calc(100% - 2rem);
      tbody {
        background-color: transparent;
      }
      thead {
        border: none;
        clip: rect(0 0 0 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
      }

      tr {
        margin: 1rem;
        display: block;
        margin-bottom: 1em;
        background-color: #edf2ee;
        padding-bottom: 0.5rem;
        width: 100% !important;
        td {
          padding: 0rem !important;
          font-size: 0.9rem;
        }
        td:nth-child(2) {
          text-align: left !important;
        }
        td:nth-child(3) {
          text-align: left !important;
        }
        td:nth-child(4) {
          text-align: left !important;
        }
        td:nth-child(5) {
          text-align: left !important;
          max-width: 50rem;
        }
        .acao {
          display: none;
        }
      }
      tr:nth-child(even) td {
        display: block;
        margin-bottom: 0.5em;
      }
      .sem-resultados {
        padding-top: 5rem;
      }

      td {
        display: block;
        min-height: 1.3rem;
        margin: 0.5rem 1rem;
        img {
          height: 1.5rem;
          margin: 0rem 1rem;
        }
      }

      td::before {
        content: attr(data-label);
        float: left;
        font-weight: bold;
        color: #0a4a22;
        font-family: "Poppins-Medium";
        margin-right: 0.5rem;
      }
      .topo-card {
        background-color: #375a3a;
        font-family: "Poppins";
        color: white;
        text-align: center;
        margin: 0rem 0rem 1rem;
        text-align: center !important;
        padding: 0.3rem 0rem !important;
      }
      .exibir-mais {
        margin-top: -1rem;
        color: black;
        text-align: left;
      }
    }
  }
  .alto-contraste {
    tr {
      background: #202020 !important;
    }
    table {
      td::before {
        color: #c2c2c2;
      }
    }
  }
}
</style>
