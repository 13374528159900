import BuscaSimplesCards from '@/components/BuscaSimplesCards.vue';

export const mixinAtoAdministrativo = {
  components: {
    BuscaSimplesCards,
  },
  data() {
    return {
        cardsAtoAdministrativo: [
            {
              rota: 'PorTipoAtoAdministrativo',
              icone: require('@/assets/images/icon-tipo-legislacao.png'),
              iconeClasse: 'legisla-icon',
              titulo: 'Tipo de Ato',
              descricao: 'Consulte Atos Administrativos por tipo',
            },
            {
              rota: 'PorAnoAtoAdministrativo',
              icone: require('@/assets/images/icon-indice-cronologico.png'),
              iconeClasse: 'indice-icon',
              titulo: 'Índice Cronológico',
              descricao: 'Consulte Atos Administrativos por ano',
            },
          ],
    };
  },
};