import BuscaSimplesCards from '@/components/BuscaSimplesCards.vue';

export const mixinLegislacao = {
  components: {
    BuscaSimplesCards,
  },
  data() {
    return {
      cardsLegislacao: [
        {
          rota: 'PorTipoLegislacao',
          icone: require('@/assets/images/icon-tipo-legislacao.png'),
          iconeClasse: 'legisla-icon',
          titulo: 'Tipo de Legislação',
          descricao: 'Consulte a Legislação por tipo',
        },
        {
          rota: 'PorCategoriaLegislacao',
          icone: require('@/assets/images/icon-categoria.png'),
          iconeClasse: 'categoria-icon',
          titulo: 'Categoria',
          descricao: 'Consulte a Legislação por categoria',
        },
        {
          rota: 'PorAutorLegislacao',
          icone: require('@/assets/images/icon-autor.png'),
          iconeClasse: 'autor-icon',
          titulo: 'Autor',
          descricao: 'Consulte a Legislação por autor',
        },
        {
          rota: 'PorAnoLegislacao',
          icone: require('@/assets/images/icon-indice-cronologico.png'),
          iconeClasse: 'indice-icon',
          titulo: 'Índice Cronológico',
          descricao: 'Consulte a Legislação por ano',
        },
      ],
    };
  },
  methods: {
    fazerBuscaSimples(event) {
      event.preventDefault();
      this.$router.push({
        name: "PesquisaLegislacao",
        query: {
          termo: this.termo,
          ordenarPor: this.ordenarPor,
          solo: this.solo,
          pagina: 1,
          qtd_por_pagina: this.qtd_por_pagina,
        },
      });
    },
    limparFiltro() {
      this.filtro = {
        numero: null,
        conteudo: null,
        tipo_legislacao: [],
        categoria_legislacao: undefined,
        ementa: null,
        autor: null,
        ano: null,
        periodo_inicial_legislacao: null,
        periodo_final_legislacao: null,
        periodo_inicial_diario: null,
        periodo_final_diario: null,
      };
      this.categoriaSelecionada = null;
      this.tiposSelecionados = [];
    },
  },
};