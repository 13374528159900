import axios from "axios";
import axiosInstance from "./axiosInstance";

const queryString = require("query-string");

class AtoInfralegalApi {
  cancelTokens = {
    getAtoInfralegal: null,
    getAtosInfralegais: null,
    getMaisRecentes: null,
    getAnos: null,
    getTipos: null,
    getOrgaos: null,
  };

  isCancel(exceptionThrown) {
    return axios.isCancel(exceptionThrown);
  }

  async getAtosInfralegais({
    page = 1,
    numero,
    qtd_por_pagina,
    conteudo,
    tipo_ato_infralegal,
    categoria_ato_infralegal,
    orgao_emissor,
    ano,
    situacao_ato_infralegal,
    periodo_inicial_data,
    periodo_final_data,
    periodo_inicial_vigencia_inicio,
    periodo_final_vigencia_inicio,
    periodo_inicial_vigencia_fim,
    periodo_final_vigencia_fim,
    numero_processo_sei,
    termo,
  }) {
    // Definir valores padrão para os parâmetros que podem ser nulos
    numero = numero !== undefined ? numero : null;
    conteudo = conteudo !== undefined ? conteudo : null;
    tipo_ato_infralegal = tipo_ato_infralegal !== undefined ? tipo_ato_infralegal : null;
    categoria_ato_infralegal = categoria_ato_infralegal !== undefined ? categoria_ato_infralegal : null;
    orgao_emissor = orgao_emissor !== undefined ? orgao_emissor : null;
    ano = ano !== undefined ? ano : null;
    periodo_inicial_data = periodo_inicial_data !== undefined ? periodo_inicial_data : null;
    periodo_final_data = periodo_final_data !== undefined ? periodo_final_data : null;
    periodo_inicial_vigencia_inicio = periodo_inicial_vigencia_inicio !== undefined ? periodo_inicial_vigencia_inicio : null;
    periodo_final_vigencia_inicio = periodo_final_vigencia_inicio !== undefined ? periodo_final_vigencia_inicio : null;
    periodo_inicial_vigencia_fim = periodo_inicial_vigencia_fim !== undefined ? periodo_inicial_vigencia_fim : null;
    periodo_final_vigencia_fim = periodo_final_vigencia_fim !== undefined ? periodo_final_vigencia_fim : null;
    termo = termo !== undefined ? termo : null;

    // Construir o objeto de parâmetros
    const queryParams = {
      page,
      numero,
      qtd_por_pagina,
      conteudo,
      tipo_ato_infralegal,
      categoria_ato_infralegal,
      orgao_emissor,
      ano,
      situacao_ato_infralegal,
      periodo_inicial_data,
      periodo_final_data,
      periodo_inicial_vigencia_inicio,
      periodo_final_vigencia_inicio,
      periodo_inicial_vigencia_fim,
      periodo_final_vigencia_fim,
      numero_processo_sei,
      termo,
    };

    // Construir a query string
    const query = queryString.stringify(queryParams);

    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    this.cancelTokens.getAtosInfralegais = source;

    const { data } = await axiosInstance.get(
      `${process.env.VUE_APP_BASE_API_URL}/api/v1/pesquisa/atos_infralegais?${query}`,
      {
        cancelToken: source.token,
      }
    );

    this.cancelTokens.getAtosInfralegais = null;

    return data;
  }

  async getAtoInfralegal(AtoInfralegalId) {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    this.cancelTokens.getAtoInfralegal = source;
    console.log(AtoInfralegalId)
    const { data } = await axiosInstance.get(
      `${process.env.VUE_APP_BASE_API_URL}/api/v1/pesquisa/atos_infralegais/${AtoInfralegalId}`,
      {
        cancelToken: source.token,
      }
    );

    this.cancelTokens.getAtoInfralegal = null;

    return data;
  }

  async getMaisRecentes() {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    this.cancelTokens.getMaisRecentes = source;

    const { data } = await axiosInstance.get(
      `${process.env.VUE_APP_BASE_API_URL}/api/v1/pesquisa/atos_infralegais/mais_recentes`,
      {
        cancelToken: source.token,
      }
    );

    this.cancelTokens.getMaisRecentes = null;

    return data;
  }

  async getAnos() {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    this.cancelTokens.getAnos = source;

    const { data } = await axiosInstance.get(
      `${process.env.VUE_APP_BASE_API_URL}/api/v1/pesquisa/atos_infralegais/anos`,
      {
        cancelToken: source.token,
      }
    );

    this.cancelTokens.getAnos = null;

    return data;
  }

  async getTipos() {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    this.cancelTokens.getTipos = source;

    const { data } = await axiosInstance.get(
      `${process.env.VUE_APP_BASE_API_URL}/api/v1/pesquisa/atos_infralegais/tipos`,
      {
        cancelToken: source.token,
      }
    );

    this.cancelTokens.getTipos = null;

    return data;
  }

  async getOrgaos() {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    this.cancelTokens.getOrgaos = source;

    const { data } = await axiosInstance.get(
      `${process.env.VUE_APP_BASE_API_URL}/api/v1/pesquisa/atos_infralegais/orgaos`,
      {
        cancelToken: source.token,
      }
    );

    this.cancelTokens.getOrgaos = null;

    return data;
  }
}

export default AtoInfralegalApi;